/* ==================================================== */
/*                      STICKER       				    */
/* ==================================================== */
.yf-sticker {
  position: absolute;

  /*I, IMG {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  }*/
}

.yf-sticker.yf-top-right {
  right: -20px; top: -20px;
  @media (max-width: $SCREEN-XS) {
    right: -10px; top: -25px;
  }
}

.yf-sticker.yf-top-left {
  left: -20px; top: -20px;
  @media (max-width: $SCREEN-XS) {
    left: -10px; top: -25px;
  }
}
