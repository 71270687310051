
/* ==================================================== */
/*                       LABELS						    */
/* ==================================================== */
// STANDARD
.yf-label {
  background-color: $COLOR-YFGREEN;
  color: $COLOR-WHITE;
  padding: 7px 7px;
  text-transform: uppercase;
  I { margin-right: 3px; }
  display: inline-block; margin-bottom: 3px; margin-right: 1px;
}
.yf-label-border {
  border: 2px solid $COLOR-YFGREEN;
  color: $COLOR-YFGREEN;
  padding: 5px 5px;
  text-transform: uppercase;
  I { margin-right: 3px; }
  display: inline-block; margin-bottom: 3px; margin-right: 1px;
}

// COLORS
.yf-label.yf-green    { background-color: $COLOR-YFGREEN; }
.yf-label.yf-blue     { background-color: $COLOR-YFBLUE; }
.yf-label.yf-violet   { background-color: $COLOR-YFVIOLET; }
.yf-label.yf-pink     { background-color: $COLOR-YFPINK; }
.yf-label.yf-orange   { background-color: $COLOR-YFORANGE; }
.yf-label.yf-white    { background-color: $COLOR-WHITE; color: $COLOR-K40; }
.yf-label.yf-k10      { background-color: $COLOR-K10; }
.yf-label.yf-k20      { background-color: $COLOR-K20; }
.yf-label.yf-k40      { background-color: $COLOR-K40; }
.yf-label.yf-k65      { background-color: $COLOR-K65; }

// COLORS: BORDER
.yf-label-border.yf-green {
  border: 2px solid $COLOR-YFGREEN;
  color: $COLOR-YFGREEN;
}
.yf-label-border.yf-blue {
  border: 2px solid $COLOR-YFBLUE;
  color: $COLOR-YFBLUE;
}
.yf-label-border.yf-orange {
  border: 2px solid $COLOR-YFPINK;
  color: $COLOR-YFPINK;
}
.yf-label-border.yf-pink {
  border: 2px solid $COLOR-YFPINK;
  color: $COLOR-YFPINK;
}
.yf-label-border.yf-violet {
  border: 2px solid $COLOR-YFVIOLET;
  color: $COLOR-YFVIOLET;
}
.yf-label-border.yf-orange {
  border: 2px solid $COLOR-YFORANGE;
  color: $COLOR-YFORANGE;
}
.yf-label-border.yf-k40 {
  border: 2px solid $COLOR-K40;
  color: $COLOR-K40;
}
.yf-label-border.yf-k65 {
  border: 2px solid $COLOR-K65;
  color: $COLOR-K65;
}


// SPECIALS
.yf-label-date {
  color: $COLOR-YFGREEN;
  background-color: $COLOR-K5;
}

.yf-label-contact-phonefax {
  background-color: $COLOR-YFBLUE;
  color: $COLOR-WHITE;
  A SPAN { color: $COLOR-WHITE; font-size: $FONT-SIZE-SMALL; }
  @media (max-width: $SCREEN-SM) {
    padding: 8px 7px; margin-bottom: 20px;
  }
}
.yf-label-contact-phonefax:hover {
  background-color: $COLOR-YFBLUE-L;
}

.yf-label-contact-email {
  background-color: $COLOR-YFGREEN;
  color: $COLOR-WHITE;
  A SPAN { color: $COLOR-WHITE; font-size: $FONT-SIZE-SMALL; }
  @media (max-width: $SCREEN-SM) {
    padding: 8px 7px; margin-bottom: 20px;
  }
}
.yf-label-contact-email:hover {
  background-color: $COLOR-YFGREEN-L;
}

// link in main nav!
.yf-label-contact-main-nav {
  background-color: $COLOR-YFGREEN;
  color: $COLOR-WHITE;
  A SPAN { color: $COLOR-WHITE; font-size: $FONT-SIZE-SMALL; }
  font-size: 100%;
}
.yf-label-contact-main-nav:hover {
  background-color: $COLOR-YFGREEN-L;
}






