/* ==================================================== */
/*                    MODALS           				    */
/* ==================================================== */

// STANDARD
.modal {
  z-index: 9999;
  @media (max-width: $SCREEN-SM) {
    padding-right: 0px !important;
  }
}
.modal-dialog {
  @media (min-width: $SCREEN-SM) {
    margin-top: 100px;
  }
  @media (min-width: $SCREEN-MD) {
  }
  @media (min-width: $SCREEN-LG) {
    width: 900px;
  }
}
.modal-header {
  text-align: left;

  H4 { padding: 0px; margin-top: 1px; color: $COLOR-K80; }
  BUTTON I { margin-top: 3px; }
}
.modal-body {
  text-align: left;

  H1 { font-size: 20px; }
  H2 { font-size: 18px; }
  H3 { font-size: 16px; }
  H4 { font-size: 14px; }
  H5 { font-size: 14px; }

  P {
    text-align: justify;
    font-size: $FONT-SIZE-SMALL;
  }

}

.modal-body {
  height:calc(100% - 125px);
  overflow-y: scroll;     /*give auto it will take based in content */
}

.yf-modal {
  .modal-title {
    color: $COLOR-K80;
    font-size: $FONT-SIZE-H3;

    .modal-footer {
      //height:75px;
      BUTTON {
        margin-bottom: 2px;
        @media (max-width: $SCREEN-MD) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.yf-modal-hide-scrollbars {
  .modal-dialog {
    .modal-content {
      .modal-body {
        overflow-y: hidden;
      }
    }

  }
}





// MODAL: COOKIES SETTINGS
.yf-modal-cookies {

  .modal-title {
    color: $COLOR-K80;
    font-size: $FONT-SIZE-H3;
  }

  .modal-subtitle {
    color: $COLOR-K80;
    font-size: $FONT-SIZE-H5;
  }

  .modal-footer {
    //height:75px;
    BUTTON {
      margin-bottom: 2px;
      @media (max-width: $SCREEN-MD) {
        margin-bottom: 5px;
      }
    }
  }

  a.cookie_ts {
    color: $COLOR-YFGREEN;
    cursor:pointer;
  }

  .cookie_table {
    TH {
      color: $COLOR-K80;
    }
    TH, TD {
      font-size: $FONT-SIZE-SMALL-EXTRA;
      @media (min-width: $SCREEN-MD) {
        font-size: $FONT-SIZE-SMALL;
      }
    }
  }

  UL {
    padding-top: 15px;

    LI LABEL {
      color: $COLOR-K80;
    }
    LI LABEL.yf-disabled {
      color: $COLOR-K40;
    }
  }
}


