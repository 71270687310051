/* ==================================================== */
/*                      ALERT         				    */
/* ==================================================== */

.yf-alert {
  display: inline-block;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 9px 18px;
  border-radius: 3px;

  font-size: $FONT-SIZE-STD;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  text-decoration: none;

  transition:all 0.5s;  // animation

  P {
    margin: 0;
  }

  I {
    margin-right: 6px;
  }
}

.yf-alert:hover {
  transform:scale(1.025);
}


.yf-alert-news {
  background: rgba(0,0,0, 0.3);

  text-align: left;

  P {
    color: $COLOR-WHITE;
    A { color: $COLOR-WHITE; }
  }


}