
/* ==================================================== */
/*                       COLORS							*/
/* ==================================================== */

// STANDARDS
.yf-c-white  { color: $COLOR-WHITE; }
.yf-c-black  { color: $COLOR-BLACK; }
.yf-c-k40    { color: $COLOR-K40; }
.yf-c-k65    { color: $COLOR-K65; }
.yf-c-k80    { color: $COLOR-K80; }
.yf-c-k90    { color: $COLOR-K90; }

.yf-c-yfgreen   { color: $COLOR-YFGREEN; }
.yf-c-yfblue    { color: $COLOR-YFBLUE; }
.yf-c-yfviolet  { color: $COLOR-YFVIOLET; }
.yf-c-yfpink    { color: $COLOR-YFPINK; }
.yf-c-yforange  { color: $COLOR-YFORANGE; }

// BACKGROUNDS
.yf-bg-white  { background-color: $COLOR-WHITE; }
.yf-bg-black  { background-color: $COLOR-BLACK; }
.yf-bg-k5     { background-color: $COLOR-K5; }
.yf-bg-k10    { background-color: $COLOR-K10; }
.yf-bg-k20    { background-color: $COLOR-K20; }
.yf-bg-k40    { background-color: $COLOR-K40; }
.yf-bg-k65    { background-color: $COLOR-K65; }
.yf-bg-k80    { background-color: $COLOR-K80; }
.yf-bg-k90    { background-color: $COLOR-K90; }

.yf-bg-yfgreen      { background-color: $COLOR-YFGREEN; }
.yf-bg-yfgreen-l    { background-color: $COLOR-YFGREEN-L; }
.yf-bg-yfgreen-d    { background-color: $COLOR-YFGREEN-D; }
.yf-bg-yfblue       { background-color: $COLOR-YFBLUE; }
.yf-bg-yfblue-l     { background-color: $COLOR-YFBLUE-L; }
.yf-bg-yfblue-d     { background-color: $COLOR-YFBLUE-D; }
.yf-bg-yfviolet     { background-color: $COLOR-YFVIOLET; }
.yf-bg-yfpink       { background-color: $COLOR-YFPINK; }
.yf-bg-yforange     { background-color: $COLOR-YFORANGE; }


.yf-opacity-50      { opacity: 0.5; }



// ================================
// BACKGROUNDS: STANDARD
// ================================

// bg yfgreen (LIGHT -> DARK)
@mixin mixin_yf-bg-linear-dg-yfgreen() {
  background: $COLOR-YFGREEN; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(189, 205, 0, 1.0), rgba(151, 191, 21, 1.0), rgba(106, 176, 38, 1.0)); /* Safari */
  background: -o-linear-gradient(left top, rgba(189, 205, 0, 1.0), rgba(151, 191, 21, 1.0), rgba(106, 176, 38, 1.0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(189, 205, 0, 1.0), rgba(151, 191, 21, 1.0), rgba(106, 176, 38, 1.0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(189, 205, 0, 1.0), rgba(151, 191, 21, 1.0), rgba(106, 176, 38, 1.0)); /* Standard syntax */
}
.yf-bg-linear-dg-yfgreen {
  @include mixin_yf-bg-linear-dg-yfgreen();
}


@mixin mixin_yf-bd-linear-dg-yfgreen() {
  border-width: 2px;
  border-style: solid;
  border-color: $COLOR-YFGREEN; /* fallback color */

  -webkit-border-image: -webkit-linear-gradient(left top, rgba(189, 205, 0, 1.0), rgba(151, 191, 21, 1.0), rgba(106, 176, 38, 1.0)); /* Safari */
  -o-border-image: -o-linear-gradient(left top, rgba(189, 205, 0, 1.0), rgba(151, 191, 21, 1.0), rgba(106, 176, 38, 1.0)); /* Opera 11.6 to 12.0 */
  -moz-border-image: -moz-linear-gradient(left top, rgba(189, 205, 0, 1.0), rgba(151, 191, 21, 1.0), rgba(106, 176, 38, 1.0)); /* Firefox 3.6 to 15 */
  border-image: linear-gradient(to bottom right, rgba(189, 205, 0, 1.0), rgba(151, 191, 21, 1.0), rgba(106, 176, 38, 1.0)); /* Standard syntax */
}
.yf-bd-linear-dg-yfgreen {
  //@include mixin_yf-bd-linear-dg-yfgreen();
  border: 2px solid transparent;
  border-radius: 80px;
  background-origin: border-box;
  background-clip: content-box, border-box;

  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #f00,#3020ff);

}


// bg yfblue (LIGHT -> DARK)
@mixin mixin_yf-bg-linear-dg-yfblue() {
  background: $COLOR-YFBLUE; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(0,137,152,1.0), rgba(0,153,169,1.0), rgba(0,176,172,1.0)); /* Safari */
  background: -o-linear-gradient(left top, rgba(0,137,152,1.0), rgba(0,153,169,1.0), rgba(0,176,172,1.0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(0,137,152,1.0), rgba(0,153,169,1.0), rgba(0,176,172,1.0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(0,137,152,1.0), rgba(0,153,169,1.0), rgba(0,176,172,1.0)); /* Standard syntax */
}
.yf-bg-linear-dg-yfblue {
  @include mixin_yf-bg-linear-dg-yfblue();
}


// bg yfpink (LIGHT -> DARK)
@mixin mixin_yf-bg-linear-dg-yfpink() {
  background: $COLOR-YFPINK; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(233,66,130,1.0), rgba(230,0,115,1.0), rgba(204,0,102,1.0)); /* Safari */
  background: -o-linear-gradient(left top, rgba(233,66,130,1.0), rgba(230,0,115,1.0), rgba(204,0,102,1.0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(233,66,130,1.0), rgba(230,0,115,1.0), rgba(204,0,102,1.0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(233,66,130,1.0), rgba(230,0,115,1.0), rgba(204,0,102,1.0)); /* Standard syntax */
}
.yf-bg-linear-dg-yfpink {
  @include mixin_yf-bg-linear-dg-yfpink();
}


// bg yforange (LIGHT -> DARK)
@mixin mixin_yf-bg-linear-dg-yforange() {
  background: $COLOR-YFORANGE; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(239,125,4,1.0), rgba(234,91,16,1.0), rgba(207,82,12,1.0)); /* Safari */
  background: -o-linear-gradient(left top, rgba(239,125,4,1.0), rgba(234,91,16,1.0), rgba(207,82,12,1.0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(239,125,4,1.0), rgba(234,91,16,1.0), rgba(207,82,12,1.0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(239,125,4,1.0), rgba(234,91,16,1.0), rgba(207,82,12,1.0)); /* Standard syntax */
}
.yf-bg-linear-dg-yforange {
  @include mixin_yf-bg-linear-dg-yforange();
}

// bg yfviolet (LIGHT -> DARK)
@mixin mixin_yf-bg-linear-dg-yfviolet() {
  background: $COLOR-YFVIOLET; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(150,64,145,1.0), rgba(112,37,131,1.0), rgba(100,32,118,1.0)); /* Safari */
  background: -o-linear-gradient(left top, rgba(150,64,145,1.0), rgba(112,37,131,1.0), rgba(100,32,118,1.0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(150,64,145,1.0), rgba(112,37,131,1.0), rgba(100,32,118,1.0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(150,64,145,1.0), rgba(112,37,131,1.0), rgba(100,32,118,1.0)); /* Standard syntax */
}
.yf-bg-linear-dg-yfviolet {
  @include mixin_yf-bg-linear-dg-yfviolet();
}


// bg dark (K80 -> BLACK)
@mixin mixin_yf-bg-linear-dg-dark() {
  background: $COLOR-BLACK; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(88,88,90,1.0), rgba(0,0,0,1.0)); /* Safari */
  background: -o-linear-gradient(left top, rgba(88,88,90,1.0), rgba(0,0,0,1.0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(88,88,90,1.0), rgba(0,0,0,1.0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(88,88,90,1.0), rgba(0,0,0,1.0)); /* Standard syntax */
}
.yf-bg-linear-dg-dark {
  @include mixin_yf-bg-linear-dg-dark();
}


// bg light grey (K0 -> K5)
.yf-bg-linear-td-light {
  background: $COLOR-K90; /* fallback color */
  background: -webkit-linear-gradient(top, rgb(255,255,255),rgb(246,246,246)); /* Safari */
  background: -o-linear-gradient(top, rgb(255,255,255),rgb(246,246,246)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(top, rgb(255,255,255),rgb(246,246,246)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom, rgb(255,255,255),rgb(246,246,246)); /* Standard syntax */
}


// ================================
// BACKGROUNDS: GRADIENTS: OVERLAYS
// ================================

// YF-GREEN
.yf-bg-overlay-linear-dg-yfgreen {
  z-index:2;
  background: $COLOR-YFGREEN; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(189,205,0,0.7),rgba(151,191,21,0.75), rgba(106,176,38,0.85)); /* Safari */
  background: -o-linear-gradient(left top, rgba(189,205,0,0.7),rgba(151,191,21,0.75), rgba(106,176,38,0.85)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(189,205,0,0.7),rgba(151,191,21,0.75), rgba(106,176,38,0.85)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(189,205,0,0.7),rgba(151,191,21,0.75), rgba(106,176,38,0.85)); /* Standard syntax */
}

// YF-BLUE
.yf-bg-overlay-linear-dg-yfblue {
  z-index:2;
  background: $COLOR-YFBLUE; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(0,137,152,0.7),rgba(0,153,169,0.75), rgba(0,176,172,0.85)); /* Safari */
  background: -o-linear-gradient(top left, rgba(0,137,152,0.7),rgba(0,153,169,0.75), rgba(0,176,172,0.85)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(top left, rgba(0,137,152,0.7),rgba(0,153,169,0.75), rgba(0,176,172,0.85)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(0,137,152,0.7),rgba(0,153,169,0.75), rgba(0,176,172,0.85)); /* Standard syntax */
}

// YF-VIOLET
.yf-bg-overlay-linear-dg-yfviolet {
  z-index:2;
  background: $COLOR-YFVIOLET; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(150,64,145,0.75), rgba(112,37,131,0.75), rgba(100,32,118,0.75)); /* Safari */
  background: -o-linear-gradient(top left, rgba(150,64,145,0.75), rgba(112,37,131,0.75), rgba(100,32,118,0.75)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(top left, rgba(150,64,145,0.75), rgba(112,37,131,0.75), rgba(100,32,118,0.75)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(150,64,145,0.75), rgba(112,37,131,0.75), rgba(100,32,118,0.75)); /* Standard syntax */
}

// YF-PINK
.yf-bg-overlay-linear-dg-yfpink {
  z-index:2;
  background: $COLOR-YFPINK; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(233,66,130,0.75), rgba(230,0,115,0.75), rgba(204,0,102,0.75)); /* Safari */
  background: -o-linear-gradient(left top, rgba(233,66,130,0.75), rgba(230,0,115,0.75), rgba(204,0,102,0.75)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(233,66,130,0.75), rgba(230,0,115,0.75), rgba(204,0,102,0.75)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(233,66,130,0.75), rgba(230,0,115,0.75), rgba(204,0,102,0.75)); /* Standard syntax */
}

// YF-PINK
.yf-bg-overlay-linear-dg-yforange {
  z-index:2;
  background: $COLOR-YFORANGE; /* fallback color */
  background: -webkit-linear-gradient(left top, rgba(239,125,4,0.7), rgba(234,91,16,0.75), rgba(207,82,12,0.85)); /* Safari */
  background: -o-linear-gradient(left top, rgba(239,125,4,0.7), rgba(234,91,16,0.75), rgba(207,82,12,0.85)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left top, rgba(239,125,4,0.7), rgba(234,91,16,0.75), rgba(207,82,12,0.85)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(239,125,4,0.7), rgba(234,91,16,0.75), rgba(207,82,12,0.85)); /* Standard syntax */
}

.yf-bg-overlay-linear-td-dark {
  z-index:2;
  background: $COLOR-K90; /* fallback color */
  background: -webkit-linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.35)); /* Safari */
  background: -o-linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.35)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.35)); /* Firefox 3.6 to 15 */
  background: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.35)); /* Standard syntax */
}
.yf-bg-overlay-linear-lr-dark {
  z-index:2;
  background: $COLOR-K90; /* fallback color */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.85),rgba(0,0,0,0.2)); /* Safari */
  background: -o-linear-gradient(left, rgba(0,0,0,0.85),rgba(0,0,0,0.2)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left, rgba(0,0,0,0.85),rgba(0,0,0,0.2)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to right, rgba(0,0,0,0.85),rgba(0,0,0,0.2)); /* Standard syntax */
}

// YF-OVERLAY-STD
.yf-bg-overlay-std-stage {
  z-index:2;
  background: $COLOR-K80; /* fallback color */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.8),rgba(0,0,0,0)); /* Safari */
  background: -o-linear-gradient(left, rgba(0,0,0,0.8),rgba(0,0,0,0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left, rgba(0,0,0,0.8),rgba(0,0,0,0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to right, rgba(0,0,0,0.8),rgba(0,0,0,0)); /* Standard syntax */
}

// YF-OVERLAY-STD
.yf-bg-overlay-std-light {
  z-index:2;
  background: none; /* fallback color */
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.15),rgba(0,0,0,0)); /* Safari */
  background: -o-linear-gradient(top left, rgba(0,0,0,0.15),rgba(0,0,0,0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.15),rgba(0,0,0,0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(0,0,0,0.15),rgba(0,0,0,0)); /* Standard syntax */
}
.yf-bg-overlay-std-light.yf-animate {
  transition:opacity 1s; // animation
  opacity: 1.0;
}
.yf-bg-overlay-std-light.yf-animate:hover {
  opacity: 0.0;
}


// YF-OVERLAY-KENBURNS
.yf-bg-overlay-kenburns {
  z-index:2;
  background: $COLOR-K80; /* fallback color */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.8),rgba(0,0,0,0)); /* Safari */
  background: -o-linear-gradient(left, rgba(0,0,0,0.8),rgba(0,0,0,0)); /* Opera 11.6 to 12.0 */
  background: -moz-linear-gradient(left, rgba(0,0,0,0.8),rgba(0,0,0,0)); /* Firefox 3.6 to 15 */
  background: linear-gradient(to right, rgba(0,0,0,0.8),rgba(0,0,0,0)); /* Standard syntax */
}




// INVERT
.yf-invert {
  H1, H2, H3, H4, H5, H6 { color: #FFFFFF; font-weight: bold; }
  P, LI P, LI DIV P, EM, B, STRONG { color: #FFFFFF; }
}
@media (min-width: $SCREEN-SM) {
  .yf-invert-from-sm {
    H1, H2, H3, H4, H5, H6 { color: #FFFFFF; font-weight: bold; }
    P { color: #FFFFFF; }
  }
}
@media (min-width: $SCREEN-MD) {
  .yf-invert-from-md {
    H1, H2, H3, H4, H5, H6 { color: #FFFFFF; font-weight: bold; }
    P { color: #FFFFFF; }
  }
}


// =================================
// BACKGROUNDS: HIGHLIGHTS (ROUNDED)
// =================================
EM.yf-text-hl {
  background-clip: padding-box; border-radius: 6px;
  //font-weight: normal;
  padding: 0px 6px 2px 6px;
  background-color: $COLOR-YFGREEN;
  color: #ffffff;
}
EM.yf-text-hl.yf-bg-yfgreen { background-color: $COLOR-YFGREEN; }
EM.yf-text-hl.yf-bg-yfblue  { background-color: $COLOR-YFBLUE; }
EM.yf-text-hl.yf-bg-yforange  { background-color: $COLOR-YFORANGE; }
EM.yf-text-hl.yf-bg-yfpink  { background-color: $COLOR-YFPINK; }
EM.yf-text-hl.yf-bg-yfviolet  { background-color: $COLOR-YFVIOLET; }