/* ==================================================== */
/*                      BANNER       				    */
/* ==================================================== */

// GENERAL
.yf-banner {
  background-color: #00A7AC;

  display: table;
  //height: 100px; max-height: 100px;
  transition: all 0.5s; // animation

  border: none;
  border-color: transparent;

  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.yf-banner:hover {
  transform:scale(1.025);
}


.yf-banner.yf-height-100 {
  IMG { max-height: 100px; }
}


