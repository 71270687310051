/* ==================================================== */
/*          FONTS, HEADERS, TEXTS, PARAGRAPHS		    */
/* ==================================================== */

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../inc/fonts/open-sans-v34-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../inc/fonts/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../inc/fonts/open-sans-v34-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../inc/fonts/open-sans-v34-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../inc/fonts/open-sans-v34-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../inc/fonts/open-sans-v34-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../inc/fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../inc/fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../inc/fonts/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../inc/fonts/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../inc/fonts/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../inc/fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: bold;
  font-weight: 600;
  src: url('../../inc/fonts/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../inc/fonts/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../inc/fonts/open-sans-v34-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../inc/fonts/open-sans-v34-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../../inc/fonts/open-sans-v34-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../inc/fonts/open-sans-v34-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

// BODY
BODY 				{ font-family: Arial, Helvetica, sans-serif; font-display: swap; } // fallback
BODY				{ font-family: 'Open Sans', sans-serif; font-display: swap; }


// HEADLINES
H1, H1 A, H1 A:active, H1 A:focus, H1 A:visited	{ font-size: $FONT-SIZE-H1; font-weight: normal; line-height: normal; color: $COLOR-TITLE-STD; }
H1 A:hover, A H1:hover	{ color: $COLOR-YFGREEN; }
H2, H2 A, H2 A:active, H2 A:focus, H2 A:visited	{ font-size: $FONT-SIZE-H2; font-weight: normal; line-height: normal; color: $COLOR-TITLE-STD; }
H2 A:hover, A H2:hover	{ color: $COLOR-YFGREEN; }
H3, H3 A, H3 A:active, H3 A:focus, H3 A:visited	{ font-size: $FONT-SIZE-H3; font-weight: normal; line-height: normal; color: $COLOR-TITLE-STD; }
H3 A:hover, A H3:hover  { color: $COLOR-YFGREEN; }
H4, H4 A, H4 A:active, H4 A:focus, H4 A:visited	{ font-size: $FONT-SIZE-H4; font-weight: normal; line-height: normal; color: $COLOR-TITLE-STD; }
H4 A:hover, A H4:hover  { color: $COLOR-YFGREEN; }
H5, H5 A, H5 A:active, H5 A:focus, H5 A:visited	{ font-size: $FONT-SIZE-H5; font-weight: normal; line-height: normal; color: $COLOR-TITLE-STD; }
H5 A:hover, A H5:hover  { color: $COLOR-YFGREEN; }
H6, H6 A, H6 A:active, H6 A:focus, H6 A:visited	{ font-size: $FONT-SIZE-H6; font-weight: normal; line-height: normal; color: $COLOR-TITLE-STD; }
H5 A:hover, A H6:hover  { color: $COLOR-YFGREEN; }

.yf-h1 { font-size: $FONT-SIZE-H1; }
.yf-h2 { font-size: $FONT-SIZE-H2; }
.yf-h3 { font-size: $FONT-SIZE-H3; }
.yf-h4 { font-size: $FONT-SIZE-H4; }
.yf-h5 { font-size: $FONT-SIZE-H5; }
.yf-h6 { font-size: $FONT-SIZE-H6; }

@media (max-width: $SCREEN-SM) {
  H1, H1 A, H1 A:active, H1 A:focus, H1 A:visited, .yf-h1	{ font-size: $FONT-SIZE-H1-SMALL; }
  H2, H2 A, H2 A:active, H2 A:focus, H2 A:visited, .yf-h2 { font-size: $FONT-SIZE-H2-SMALL; }
  H3, H3 A, H3 A:active, H3 A:focus, H3 A:visited, .yf-h3 { font-size: $FONT-SIZE-H3-SMALL; }
  H4, H4 A, H4 A:active, H4 A:focus, H4 A:visited, .yf-h4 { font-size: $FONT-SIZE-H4-SMALL; }
  H5, H5 A, H5 A:active, H5 A:focus, H5 A:visited, .yf-h5 { font-size: $FONT-SIZE-H5-SMALL; }
  H6, H6 A, H6 A:active, H6 A:focus, H6 A:visited, .yf-h6 { font-size: $FONT-SIZE-H6-SMALL; }
}

H1, H2, H3, H4, H5, H6 {
  margin-bottom: 3px;
}


// LINKS (NORMALIZE)
A, A:hover, A:active, A:focus, A:visited    { text-decoration: none; }
A, A:active, A:focus, A:visited             { color: #58585A; }
A:hover                                     { color: #97BF15; }

A.yf-icon, A.yf-icon:focus, A.yf-icon:active, A.yf-icon:visited {
  I {
    margin-right: 4px;
  }
}
A.yf-invert {
  color: $COLOR-WHITE;
  H1, H2, H3, H4, H5, H6 { color: $COLOR-WHITE; }
  font-weight: bold;
}
A.yf-invert:hover {
  color: $COLOR-WHITE;
  H1, H2, H3, H4, H5, H6 { color: $COLOR-WHITE;}
  // text-decoration: underline;
}


// TEXTS & PARAGRAPHS
@mixin mixin_yf-text-standard() {
  font-size: $FONT-SIZE-STD; font-weight: normal; line-height: 1.75; color: $COLOR-TEXT-STD;
}

P, SPAN, UL LI {
  @include mixin_yf-text-standard();
}

@media (max-width: $SCREEN-XS) {
  P, SPAN, UL LI {
    font-size: $FONT-SIZE-SMALL;
  }
}

// TEXTS & PARAGRAPHS 2024
.yf-text-2024 P {
  font-size: $FONT-SIZE-STD-LG-2024; font-weight: normal; line-height: 1.75;
}
.yf-text-2024 H1+P {
  font-size: $FONT-SIZE-LARGE-2024; font-weight: normal; line-height: 1.75;

  @media (max-width: $SCREEN-SM) {
    font-size: $FONT-SIZE-STD-LG-2024;
  }
}
@media (max-width: $SCREEN-XS) {
  .yf-text-2024 P, .yf-text-2024 H1+P {
    font-size: $FONT-SIZE-STD-XS-2024;
  }
}


P {
  margin-bottom: 20px;

  .yf-teaser {
    //font-size: 18px;
    font-weight: bold;
  }
}

.yf-justify P {
  text-align: justify;
}


.yf-font-small, P.yf-font-small { font-size: $FONT-SIZE-SMALL; }
.yf-font-super-small, P.yf-font-super-small { font-size: $FONT-SIZE-SMALL-EXTRA; }


// SPECIALS
.yf-supertitle {
  H1, H2, H3, H4, H5, H6 {
    font-size: $FONT-SIZE-SUPER; font-weight: bold;
    padding: 20px;
    text-transform: uppercase;
    word-wrap: break-word;
  }
}
@media (max-width: $SCREEN-SM) {
  .yf-supertitle {
    H1, H2, H3, H4, H5, H6 {
      font-size: $FONT-SIZE-SUPER-SMALL; font-weight: bold;
      padding: 15px 0px 0px 0px;
      text-transform: none;
    }
  }
}



.yf-seo {
  H1, H2, H3, H4, H5, H6 {
    font-size: $FONT-SIZE-LARGE;
    background: rgba(151,191,29,.75);
    padding: 5px; margin-bottom: 15px;
    display: inline-block;
    text-transform: none;

    @media (max-width: $SCREEN-XS) {
      background: none;
      font-size: $FONT-SIZE-SMALL;
    }
  }
  P:first-of-type {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.25;
    text-transform: uppercase;

    @media (max-width: $SCREEN-XS) {
      font-size: $FONT-SIZE-H1-SMALL;
    }
  }

  P:nth-child(3) {
    font-size: $FONT-SIZE-LARGE;

    @media (max-width: $SCREEN-XS) {
      font-size: $FONT-SIZE-SMALL;
    }

  }
}


SECTION {
  .yf-seo {
    H1, H2, H3, H4, H5, H6 {
      color: $COLOR-TITLE-STD;
    }
    P:first-of-type {
      font-size: $FONT-SIZE-H2;
      color: $COLOR-TITLE-STD;
      text-transform: none; font-weight: normal;

      @media (max-width: $SCREEN-XS) {
        font-size: $FONT-SIZE-H2-SMALL;
      }
    }
  }
}

BLOCKQUOTE {
  font-size: $FONT-SIZE-LARGE;
  color: $COLOR-K80;
  margin: 20px 0 20px 40px;
  padding-left: 20px;
  font-style: italic;
  border-color: $COLOR-YFBLUE-L;

  P {
    font-size: $FONT-SIZE-LARGE;
    color: $COLOR-TEXT-STD;
  }
}


DIV.yf-stock-legal {
  padding: 0 0 20px 0; margin: 0;
  font-size: $FONT-SIZE-SMALL;
  color: $COLOR-TEXT-STD;

}


.yf-text-large {
  @media (min-width: $SCREEN-SM) {
    font-size: $FONT-SIZE-LARGE;
  }
}



