/* ==================================================== */
/*                      TABLES       				    */
/* ==================================================== */

// STANDARDS: YF-TABLE
TABLE.yf-table {
  //min-width: 0;
  word-wrap: break-word;
  background-color: $COLOR-WHITE;
  background-clip: border-box;

  border: none;
  border-color: transparent;
  border-radius: 12px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);

  margin-bottom: 30px;

  border: 20px solid $COLOR-WHITE;

  TR TH {
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight:bold;
    padding-bottom: 20px;
  }
  TR TD {
    font-size: 14px;
    border:none;
    padding-top: 15px; padding-bottom: 15px;
    P { font-size: 12px; margin: 0;}

    border-top: 1px solid $COLOR-K10;
  }

  @media (max-width: $SCREEN-SM) {
    TR TH, TR TD {
      font-size: 12px;
      P { font-size: 12px; }
    }
  }
  @media (max-width: $SCREEN-XS) {
    TR TH, TR TD {
      font-size: 10px;
      P { font-size: 10px; }
    }
  }
}


//PRICING
TABLE.yf-table-pricing {
  TR TD {
    border: 0;
  }
  TR.yf-primary TD:nth-child(1) {
    padding-left: 5px;

    I {
      -moz-transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      font-size: 12px;
      padding: 0 3px 0 3px;
    }
    I.yf-rotate {
      padding: 0 10px 0 10px;
    }
    I.yf-inactive {
      color: transparent;
      padding: 0 10px 0 10px;
    }
    @media (max-width: $SCREEN-XS) {
      I, I.yf-rotate, I.yf-inactive {
        display: none;
      }
    }

  }
  TR.yf-primary.yf-down {
    TD:nth-child(1) I.yf-rotate {
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    TD {
      color: $COLOR-K20;
    }
  }


  TR.yf-primary TD {
    font-size: 16px;
    background-color: $COLOR-K5;
    border-bottom: 3px solid $COLOR-WHITE;
    cursor: pointer;
  }


  TR.yf-secondary {
    border-bottom: 1px solid $COLOR-K10;
  }
  TR.yf-secondary TD:nth-child(1) {
    padding-left: 36px;
    @media (max-width: $SCREEN-XS) {
      padding-left: 5px;
      I, I.yf-rotate, I.yf-inactive {
        display: none;
      }
    }
    @media (max-width: $SCREEN-SM) {
      padding-left: 5px;
    }
  }


  @media (min-width: $SCREEN-SM) {
    TR TD:first-child {
      min-width: 220px;
    }
    TR TD {
      min-width: 120px;
    }
  }
  @media (min-width: $SCREEN-LG) {
    TR TD:first-child {
      min-width: 300px;
    }
    TR TD {
      min-width: 150px;
    }
  }

  @media (max-width: $SCREEN-SM) {
    TR TH, TR TD, TR.yf-primary TD, TR.yf-secondary TD {
      font-size: 12px;
      P { font-size: 12px; }
    }
  }
  @media (max-width: $SCREEN-XS) {
    TR TH, TR TD, TR.yf-primary TD, TR.yf-secondary TD {
      font-size: 10px;
      P { font-size: 10px; }
    }
  }
}




// SPECS
TABLE.yf-table-specs {
  width:100%;

  // title
  TR:nth-child(odd) TD {
    border-bottom: 1px solid $COLOR-K10;
    padding: 3px 3px 3px 3px;
    font-size: 18px; font-weight: bold;
    text-transform: uppercase;
  }
  // content
  TR:nth-child(even) TD {
    padding: 3px 3px 9px 3px;
    @include mixin_yf-text-standard();
  }
}

// INVERT
TABLE.yf-table-specs-invert TR TD, TABLE.yf-table-specs-invert TR:nth-child(even) TD {
  color:white;
}
TABLE.yf-table-specs-invert TR:nth-child(odd) TD {
  border-bottom: 1px solid $COLOR-WHITE;
}


// REFERENCE
TABLE.yf-table-reference {
  width:100%;

  // title
  TR:nth-child(odd) TD {
    border-bottom: 1px solid $COLOR-K10;
    padding: 3px 3px 3px 0;
    color: $COLOR-YFORANGE;
    font-size: 14px; //font-weight: bold;
    text-transform: uppercase;
    text-align:right;
  }

  // content
  TR:nth-child(even) TD {
    padding: 3px 3px 9px 0;
    color: $COLOR-K80;
    font-size: 24px; //font-weight: bold;
    text-align: right;
    H1, H2, H3, H4, H5, H6 {
      color: $COLOR-K80;
      font-size: 24px; //font-weight: bold;
    }
  }
}



// STANDARDS
TABLE.yf-cookies {
  width: 100%;

  TH, TD {
    padding: 5px 10px 5px 10px;

    @media (max-width: $SCREEN-SM) {
      padding: 5px;
    }
    @media (max-width: $SCREEN-XS) {
      padding: 3px;
      word-break: break-all;
    }
  }

  TH {
    background-color: $COLOR-K10;
  }

  TD {
  }
}