/* ==================================================== */
/*                        ICONS     				    */
/* ==================================================== */

// GENERAL (STACKED)
SPAN.yf-icon, DIV.yf-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-bottom: 10px;

  I, IMG {
    color: $COLOR-WHITE;

    min-width: 100px;
    height: 100px;
    font-size: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    //position: relative; top: 22px;

    /*display: table-cell;
    text-align: center;
    vertical-align: middle;*/
  }

  I.yf-text {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.35);
  }
}

// SIZES
I.yf-icon-size-18, IMG.yf-icon-size-18       { width: 18px; height: 18px; font-size: 18px; }
I.yf-icon-size-24, IMG.yf-icon-size-24       { width: 24px; height: 24px; font-size: 24px; }
I.yf-icon-size-32, IMG.yf-icon-size-32       { width: 32px; height: 32px; font-size: 32px; }
I.yf-icon-size-50, IMG.yf-icon-size-50       { width: 50px; height: 50px; font-size: 50px; }
I.yf-icon-size-64, IMG.yf-icon-size-64       { width: 64px; height: 64px; font-size: 64px; }
I.yf-icon-size-100, IMG.yf-icon-size-100     { width: 100px; height: 100px; font-size: 100px; }

// SIZES (STACKED)
SPAN.yf-icon-size-18, DIV.yf-icon-size-18    { width: 18px;   height: 18px;  I { font-size: 9px;   } IMG { min-width: 9px; height: 9px;    }  I.yf-text { font-size:  6px; } }
SPAN.yf-icon-size-24, DIV.yf-icon-size-24    { width: 24px;   height: 24px;  I { font-size: 12px;  } IMG { min-width: 12px; height: 12px;  }  I.yf-text { font-size:  8px; } }
SPAN.yf-icon-size-32, DIV.yf-icon-size-32    { width: 32px;   height: 32px;  I { font-size: 16px;  } IMG { min-width: 20px; height: 20px;  }  I.yf-text { font-size: 10px; } }
SPAN.yf-icon-size-50, DIV.yf-icon-size-50    { width: 50px;   height: 50px;  I { font-size: 25px;  } IMG { min-width: 32px; height: 32px;  }  I.yf-text { font-size: 14px; } }
SPAN.yf-icon-size-64, DIV.yf-icon-size-64    { width: 64px;   height: 64px;  I { font-size: 32px;  } IMG { min-width: 40px; height: 40px;  }  I.yf-text { font-size: 20px; } }
SPAN.yf-icon-size-100, DIV.yf-icon-size-100  { width: 100px;  height: 100px; I { font-size: 50px;  } IMG { min-width: 68px; height: 68px;  }  I.yf-text { font-size: 30px; } }


// COLORS: ICONS
UL.yf-icon-white LI SPAN I,    SPAN.yf-icon-white I,    I.yf-icon-white     { color: $COLOR-WHITE; }
UL.yf-icon-yfgreen LI SPAN I,  SPAN.yf-icon-yfgreen I,  I.yf-icon-yfgreen   { color: $COLOR-YFGREEN; }
UL.yf-icon-yfblue LI SPAN I,   SPAN.yf-icon-yfblue I,   I.yf-icon-yfblue    { color: $COLOR-YFBLUE; }
UL.yf-icon-yfpink LI SPAN I,   SPAN.yf-icon-yfpink I,   I.yf-icon-yfpink    { color: $COLOR-YFPINK; }
UL.yf-icon-yfviolet LI SPAN I, SPAN.yf-icon-yfviolet I, I.yf-icon-yfviolet  { color: $COLOR-YFVIOLET; }
UL.yf-icon-yforange LI SPAN I, SPAN.yf-icon-yforange I, I.yf-icon-yforange  { color: $COLOR-YFORANGE; }



// COLORS: BACKGROUNDS
UL.yf-icon-round-bg-white LI SPAN, IMG.yf-icon-round-bg-white, I.yf-icon-round-bg-white, SPAN.yf-icon-round-bg-white {
  border-radius: 50%;
  background-color: $COLOR-WHITE;
}
UL.yf-icon-round-bg-yfgreen LI SPAN, IMG.yf-icon-round-bg-yfgreen, I.yf-icon-round-bg-yfgreen, SPAN.yf-icon-round-bg-yfgreen {
  border-radius: 50%;
  @include mixin_yf-bg-linear-dg-yfgreen();
}
UL.yf-icon-round-bg-yfblue LI SPAN, IMG.yf-icon-round-bg-yfblue, I.yf-icon-round-bg-yfblue, SPAN.yf-icon-round-bg-yfblue {
  border-radius: 50%;
  @include mixin_yf-bg-linear-dg-yfblue();
}
UL.yf-icon-round-bg-yfviolet LI SPAN, IMG.yf-icon-round-bg-yfviolet, I.yf-icon-round-bg-yfviolet, SPAN.yf-icon-round-bg-yfviolet {
  border-radius: 50%;
  @include mixin_yf-bg-linear-dg-yfviolet();
}
UL.yf-icon-round-bg-yfpink LI SPAN, IMG.yf-icon-round-bg-yfpink, I.yf-icon-round-bg-yfpink, SPAN.yf-icon-round-bg-yfpink {
  border-radius: 50%;
  @include mixin_yf-bg-linear-dg-yfpink();
}
UL.yf-icon-round-bg-yforange LI SPAN, IMG.yf-icon-round-bg-yforange, I.yf-icon-round-bg-yforange, SPAN.yf-icon-round-bg-yforange {
  border-radius: 50%;
  @include mixin_yf-bg-linear-dg-yforange();
}


// COLORS: BORDERS
UL.yf-icon-round-bd-white LI SPAN, IMG.yf-icon-round-bd-white, I.yf-icon-round-bd-white, SPAN.yf-icon-round-bd-white {
  border-radius: 50%;
  border: 2px solid $COLOR-WHITE;
  //background-color: rgba(0, 0, 0, .25);
}
UL.yf-icon-round-bd-yfgreen LI SPAN, IMG.yf-icon-round-bd-yfgreen, I.yf-icon-round-bd-yfgreen, SPAN.yf-icon-round-bd-yfgreen {
  border-radius: 50%;
  border: 2px solid $COLOR-YFGREEN;
  //background-color: rgba(0, 0, 0, .25);
}
UL.yf-icon-round-bd-yfblue LI SPAN, IMG.yf-icon-round-bd-yfblue, I.yf-icon-round-bd-yfblue, SPAN.yf-icon-round-bd-yfblue {
  border-radius: 50%;
  border: 2px solid $COLOR-YFBLUE;
  //background-color: rgba(0, 0, 0, .25);
}
UL.yf-icon-round-bd-yfpink LI SPAN, IMG.yf-icon-round-bd-yfpink, I.yf-icon-round-bd-yfpink, SPAN.yf-icon-round-bd-yfpink {
  border-radius: 50%;
  border: 2px solid $COLOR-YFPINK;
  //background-color: rgba(0, 0, 0, .25);
}
UL.yf-icon-round-bd-yfviolet LI SPAN, IMG.yf-icon-round-bd-yfviolet, I.yf-icon-round-bd-yfviolet, SPAN.yf-icon-round-bd-yfviolet {
  border-radius: 50%;
  border: 2px solid $COLOR-YFVIOLET;
  //background-color: rgba(0, 0, 0, .25);
}
UL.yf-icon-round-bd-yforange LI SPAN, IMG.yf-icon-round-bd-yforange, I.yf-icon-round-bd-yforange, SPAN.yf-icon-round-bd-yforange {
  border-radius: 50%;
  border: 2px solid $COLOR-YFORANGE;
  //background-color: rgba(0, 0, 0, .25);
}


