/* ==================================================== */
/*                        ICONS  						*/
/* ==================================================== */
SPAN I.fa-play {
   padding-left: 10%;
}
SPAN I.fa-clone {
    padding-left: 4%;
    padding-bottom: 8%;
}
SPAN I.fa-puzzle-piece {
    padding-left: 8%;
    padding-bottom: 8%;
}



/* ==================================================== */
/*                       DIVIDERS  						*/
/* ==================================================== */
HR,
HR.yf-white {
    border: 1px solid $COLOR-WHITE;
}

HR.yf-lightgrey {
    border: 1px solid $COLOR-K10;
}




/* ========================= */
/*         ???         */
/* ========================= */

.yf-bg-img {
    //background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("https://unsplash.imgix.net/photo-1416339442236-8ceb164046f8?q=75&fm=jpg&s=8eb83df8a744544977722717b1ea4d09");
    background-image: url("https://unsplash.imgix.net/photo-1416339442236-8ceb164046f8?q=75&fm=jpg&s=8eb83df8a744544977722717b1ea4d09");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: #fff;
    //height: 450px;
    //padding-top: 50px;
}

.yf-bg-img.yf-parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.yf-bg-img DIV > H1 {
    color: #FFFFFF;
}

.yf-bg-img-left-50 {

    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    padding-top: 0;
}







/* ==================================================== */
/*                       SECTIONS						*/
/* ==================================================== */
// GENERAL
SECTION, ARTICLE {
    H1, H2, H3, H4, H5, H6 {
        font-size: 36px;
    }
    padding-top:    $SECTION-PADDING-HEIGHT-STD;
    padding-bottom: $SECTION-PADDING-HEIGHT-STD;
}

@media (max-width: $SCREEN-SM) {
    SECTION, ARTICLE {
        H1, H2, H3, H4, H5, H6 {
            font-size: 30px;
        }
        padding-top:    $SECTION-PADDING-HEIGHT-SMALL;
        padding-bottom: $SECTION-PADDING-HEIGHT-SMALL;
    }
}


.yf-section {
    padding-top: $SECTION-PADDING-HEIGHT-STD;
    padding-bottom: $SECTION-PADDING-HEIGHT-STD;

    @media (max-width: $SCREEN-SM) {
        padding-top: $SECTION-PADDING-HEIGHT-SMALL;
        padding-bottom: $SECTION-PADDING-HEIGHT-SMALL;
    }
}


.yf-section-headers-std {
    H1 { font-size: $FONT-SIZE-H1; }
    H2 { font-size: $FONT-SIZE-H2; }
    H3 { font-size: $FONT-SIZE-H3; }
    H4 { font-size: $FONT-SIZE-H4; }
    H5 { font-size: $FONT-SIZE-H5; }
    H6 { font-size: $FONT-SIZE-H6; }
}

.yf-section-headers-small {
    H1 { font-size: $FONT-SIZE-H1-SMALL; }
    H2 { font-size: $FONT-SIZE-H2-SMALL; }
    H3 { font-size: $FONT-SIZE-H3-SMALL; }
    H4 { font-size: $FONT-SIZE-H4-SMALL; }
    H5 { font-size: $FONT-SIZE-H5-SMALL; }
    H6 { font-size: $FONT-SIZE-H6-SMALL; }
}

.yf-bottom-line {
    border-bottom: 3px solid $COLOR-K40;
}
.yf-bottom-line.yf-green    { border-bottom-color: $COLOR-YFGREEN; }
.yf-bottom-line.yf-violet   { border-bottom-color: $COLOR-YFVIOLET; }
.yf-bottom-line.yf-pink     { border-bottom-color: $COLOR-YFPINK; }





/* ==================================================== */
/*                      RIBBON       				    */
/* ==================================================== */
.yf-ribbon {
    position: absolute;
    right: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
}
.yf-ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: $COLOR-K80;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: $COLOR-WHITE;
    //background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
}
.yf-ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid $COLOR-YFBLUE;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid $COLOR-YFBLUE;
}
.yf-ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid $COLOR-YFBLUE;
    border-bottom: 3px solid transparent;
    border-top: 3px solid $COLOR-YFBLUE;
}




/* ==================================================== */
/*                      PRICING                  		*/
/* ==================================================== */
.yf-pricing-switch-title {
    P {
        //color: $COLOR-WHITE;
        margin:0; padding:0 0 5px 0;
    }
    DIV {
        font-size: 24px;
        //color: $COLOR-WHITE;
        font-weight:bold;
    }
}



/* ==================================================== */
/*                      DOWNLOADS                  		*/
/* ==================================================== */
.yf-downloads {
    padding: 0;
    margin-bottom: 20px;

    LI {
        padding: 10px 15px 15px 15px;
        margin: 0 0 15px 0;
        background-color: $COLOR-WHITE;

        display: block;
        clear: both;
        overflow: hidden;

        border-radius: 3px;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);

        transition: all 0.5s;

        DIV {
            P {
                text-align: left;
            }
            P:first-child {
                padding-top: 3px;
                color: $COLOR-TITLE-STD;
            }
            P:nth-child(2) {
                font-size: $FONT-SIZE-SMALL;
            }

        }

        @media (max-width: $SCREEN-XS) {
        }

    }

    LI:hover {
        transform: scale(1.02);
    }
}

.yf-downloads.yf-side {
    DIV {
        width: 100%;
        display: block;
    }
}



/* ==================================================== */
/*                      IMAGES       				    */
/* ==================================================== */

// STRETCH
IMG.yf-stretch {
    width: 100%;
}

// COVER WHOLE AREA
.yf-img-cover, .yf-img-wallpaper {
    object-fit: cover;
    height: 100%;
    width: 100%;
}


// OUTLINE IMAGES
IMG.yf-2d-outlines {
    padding: 150px 0 15px 0;
}

@media (max-width: $SCREEN-MD) {
    IMG.yf-2d-outlines {
        padding: 15px 0;
    }
}








/* ==================================================== */
/*                      GALLERY       				    */
/* ==================================================== */

.yf-gallery {

    .yf-thumbnail {
        position: relative;
        display: block;
        overflow: hidden;
        margin-bottom: 25px;

        padding: 0; border-radius: 0; border: none;
        background-color: transparent;
        -webkit-transition: border .2s ease-in-out;
        transition: border .2s ease-in-out;

    }

    .yf-thumbnail IMG {
        position: relative;
        width:100%;
        object-fit: cover;
        transition:all 1s;
    }

    .yf-thumbnail:hover IMG {
        transform:scale(1.05);
    }

    .yf-overlay {
        position: absolute;
        display:block;
        top: 0; left: 0; bottom: 0; right: 0;
        text-align: center;
        vertical-align: middle;
        margin: 0 auto;
        background: rgba(0,153,169,.0);
        transition:all 0.6s;
        /*background-size:50px 50px;*/
        /*background-color: #3DC0F1; opacity:0;*/
        //filter:alpha(opacity=0);/*For IE6 - IE8*/

        .yf-overlay-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width:100%;
            opacity: 0.0;
            transition:all 0.6s;

            SPAN {
            }

            SPAN I {
                min-width: 100px;
                height: 100px;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                display: table-cell;
                color: #FFFFFF;
                font-size: 50px;
                vertical-align:middle;

                background: #97bf15;
                background: -webkit-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: -o-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: -moz-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
            }

            .fa-play {
                padding-left: 9px;
            }

            .fa-flag {
                padding-bottom: 2px;
            }
        }

        .yf-video {
            opacity: 1.0;
            transition: all 1s;

            SPAN I {
                border: 2px solid #FFFFFF;
                color: #FFFFFF;
                background: rgba(0,0,0,.5);
            }
        }
    }

    .yf-overlay:hover {
        background: rgba(0,153,169,.8); //opacity:0.8;
        visibility: visible;
        //transform:translateY(0px);

        .yf-overlay-icon {
            opacity:1;
        }

        .yf-video {
            SPAN I {
                background: #97bf15;
                background: -webkit-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: -o-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: -moz-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: radial-gradient(circle,#bdcd00,#97bf15,#6ab026);

            }
        }
    }

}



/*
.yf-gallery-fitted {

    .thumbnail {
        position: relative;
        display: block;
        overflow:hidden;
        min-height: $MAX-HEIGHT-THUMBNAILS-LARGE;

        padding: 0; border-radius: 0; border: none;
        background-color: transparent;
        -webkit-transition: border .2s ease-in-out;
        transition: border .2s ease-in-out;

    }

    .thumbnail IMG {
        position: absolute;
        top:50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        transform: translateX(-50%) translateY(-50%);
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        display:block;
        top: 0; left: 0; bottom: 0; right: 0;
        text-align: center;
        vertical-align: middle;
        margin: 0 auto;
        background: rgba(0,153,169,.0);
        transition:all 0.6s;

        .yf-overlay-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width:100%;
            opacity: 0.0;
            transition:all 0.6s;

            SPAN {
            }

            SPAN I {
                min-width: 100px;
                height: 100px;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                display: table-cell;
                color: #FFFFFF;
                font-size: 50px;
                vertical-align:middle;

                background: #97bf15;
                background: -webkit-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: -o-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: -moz-radial-gradient(circle,#bdcd00,#97bf15,#6ab026);
                background: radial-gradient(circle,#bdcd00,#97bf15,#6ab026);

            }

            .fa-play {
                padding-left: 9px;
            }

            .fa-flag {
                padding-bottom: 2px;
            }
        }
    }

    .overlay:hover {
        background: rgba(0,153,169,.8);
        visibility: visible;

        .yf-overlay-icon {
            opacity:1;
        }
    }

} */


/* ==================================================== */
/*                    ACCORDION       				    */
/* ==================================================== */

.yf-accordion {
    .panel-default > .panel-heading {

        background-color: $COLOR-WHITE;
        border-color: $COLOR-K20;

        A, A:active, A:focus, A:visited {
            font-size: $FONT-SIZE-LARGE;
            color: $COLOR-K65;
        }
        A:hover {
            color: $COLOR-YFGREEN;
        }
    }

    .panel-default > .panel-heading.active {
        A, A:active, A:focus, A:visited {
            color: $COLOR-YFGREEN;
        }
    }
}

// added by JS (tag: active)
#accordion .panel-heading a:after {
    font-family: 'Font Awesome\ 5 Free';
    content: '\f106';
    font-weight: 900;

    color: $COLOR-TEXT-STD;
    float: right;
    padding-bottom:5px;
    transform: rotate(180deg);
    transition: all 0.5s;
}
#accordion .panel-heading.active a:after {
    //padding-bottom:5px;
    transform: rotate(0deg);
    transition: all 0.5s;
    padding-top:2px;
}





