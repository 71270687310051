/* ==================================================== */
/*                    NORMALIZE							*/
/* ==================================================== */
body, div, dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6,
pre, form, fieldset, input, p, blockquote, th, td
{ margin: 0; padding: 0; }

h1, h2, h3, h4, h5, h6
{ font-size: 100%; }

ol, ul
{ list-style: none; }

adress, caption, cite, code, dfn, em, strong, th, var
{ font-style: normal; font-weight: normal; }

table
{ border-collapse: collapse; border-spacing: 0; }

fieldset, img
{ border: 0; }

caption, th
{ text-align: left; }

q:before, q:after
{ content: ''; }

button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

img { border: 0px; }

strong, em { font-weight: bold; }

A			{ outline: none; }
A IMG 		{ border: 0px; border: none; }
A:LINK 		{ text-decoration:none; border: 0px; }
A:VISITED 	{ text-decoration:none; border: 0px; }
A:FOCUS 	{ text-decoration:none; border: 0px; }
A:ACTIVE 	{ text-decoration:none; border: 0px; }
A:HOVER 	{ text-decoration:none; border: 0px; }

div.null    { display: inline; margin: 0; padding: 0; border: 0; }