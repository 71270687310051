/* CSS Document */

DIV.yf-form {
    background-color: #00b3ee;
    transition:all 0.5s;  // animation

    //min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    //border-radius: .3rem;
    border-radius: 0px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);

    padding: 24px;
    margin-bottom: 30px;


}


FORM {
    float: none;   width: 100%;

    H1, H2, H3, H4, H5, H6 {
        border-bottom: 1px solid $COLOR-K20;
        color: $COLOR-YFGREEN;
        padding-bottom: 12px; margin-bottom: 24px;
    }

    LABEL {
        font-size: $FONT-SIZE-SMALL; font-weight: bold; color: $COLOR-TITLE-STD; margin-left: 2px;

        A, A:link, A:active, A:visited {
            color: $COLOR-YFGREEN;
        }
        A:hover {
            text-decoration: underline;
        }

    }
    .yf-optional LABEL {
        font-weight: normal; color: #87888A;
    }

    .has-error {
        P, P.yf-important, LABEL {
            color: #a94442;
        }
        DIV.g-recaptcha DIV DIV IFRAME {
            border: solid 2px #a94442;
        }
    }

    .has-success {
        P, P.yf-important, LABEL {
            color: $COLOR-YFGREEN;
        }
    }

    .yf-important {
        font-weight: bold; color: $COLOR-TITLE-STD;
    }

    


    BUTTON.yf-btn-form-submit, BUTTON.yf-btn-form-submit:active, BUTTON.yf-btn-form-submit:focus, BUTTON.yf-btn-form-submit:active:focus {
        background-color: #A5CD00;
        color: $COLOR-WHITE;
        border:2px solid $COLOR-YFGREEN;
        transition: 0.5s;

        font-size:18px; line-height:18px; font-weight:400; color:$COLOR-WHITE; text-align:center;
        padding-top:10px; padding-bottom:10px; margin-right:6px; margin-bottom:10px; margin-top: 20px;
        white-space: normal;
    }

    BUTTON.yf-btn-form-submit:hover {
        background-color: $COLOR-YFGREEN-D;
        border: 2px solid $COLOR-YFGREEN-D;
    }

}



// ----------
// YF-SWTICH
// --------------------
.yf-switch,
.yf-switch-style,
.yf-switch-style:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.yf-switch {
    display: inline-block;
    font-size: 125%;
    height: 1.6em;
    position: relative;
}
.yf-switch .yf-switch-style {
    height: 1.6em;
    left: 0;
    background: $COLOR-K20;
    -webkit-border-radius: 0.8em;
    border-radius: 0.8em;
    display: inline-block;
    position: relative;
    top: 0;
    -webkit-transition: all 0.01s ease-in-out;
    transition: all 0.01s ease-in-out;
    width: 3em;
    cursor: pointer;
}
.yf-switch .yf-switch-style:before {
    display: block;
    content: '';
    height: 1.4em;
    position: absolute;
    width: 1.4em;
    background-color: $COLOR-WHITE;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    left: 0.1em;
    top: 0.1em;
    -webkit-transition: all 0.01s ease-in-out;
    transition: all 0.01s ease-in-out;
}
.yf-switch > input[type="checkbox"] {
    display: none;
}
.yf-switch > input[type="checkbox"]:checked + .yf-switch-style {
    background-color: $COLOR-YFGREEN;
}
.yf-switch > input[type="checkbox"]:checked + .yf-switch-style:before {
    left: 50%;
}
.yf-switch > input[type="checkbox"][disabled] + .yf-switch-style {
    cursor: not-allowed;
    background-color: #D3DCE6;
}
.yf-switch > input[type="checkbox"]:checked[disabled] + .yf-switch-style {
    background-color: #b0d7f5;
}



