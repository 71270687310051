/* ==================================================== */
/*                    TOOLTIP       				    */
/* ==================================================== */
/* Base styles for the element that has a tooltip */
[data-tooltip], .yf-tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.yf-tooltip:before,
.yf-tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform:    translate3d(0, 0, 0);
  transform:         translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.yf-tooltip:hover:before,
.yf-tooltip:hover:after,
.yf-tooltip:focus:before,
.yf-tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.yf-tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.yf-tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  border-radius: 3px;
  background-color: $COLOR-YFGREEN;
  //background-color: hsla(0, 0%, 20%, 0.9);
  color: $COLOR-WHITE;
  content: attr(data-tooltip);
  line-height: 1.2;
  text-align: center;

  @media (min-width: $SCREEN-MD) {
    font-size: 14px;
    width: 200px;
  }
  @media (max-width: $SCREEN-SM) {
    font-size: 10px;
    width: 100px;
  }

}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.yf-tooltip:before,
.yf-tooltip:after,
.yf-tooltip-top:before,
.yf-tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:before,
.yf-tooltip:before,
.yf-tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: $COLOR-YFGREEN;
  //border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.yf-tooltip:after,
.yf-tooltip-top:after {
  @media (min-width: $SCREEN-MD) {
    margin-left: -100px;
  }
  @media (max-width: $SCREEN-SM) {
    margin-left: -50px;
  }

}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.yf-tooltip:hover:before,
.yf-tooltip:hover:after,
.yf-tooltip:focus:before,
.yf-tooltip:focus:after,
.yf-tooltip-top:hover:before,
.yf-tooltip-top:hover:after,
.yf-tooltip-top:focus:before,
.yf-tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform:    translateY(-12px);
  transform:         translateY(-12px);
}

/* Left */
.yf-tooltip-left:before,
.yf-tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.yf-tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: $COLOR-YFGREEN;
  //border-left-color: hsla(0, 0%, 20%, 0.9);
}

.yf-tooltip-left:hover:before,
.yf-tooltip-left:hover:after,
.yf-tooltip-left:focus:before,
.yf-tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform:    translateX(-12px);
  transform:         translateX(-12px);
}

/* Bottom */
.yf-tooltip-bottom:before,
.yf-tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.yf-tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: $COLOR-YFGREEN;
  //border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.yf-tooltip-bottom:hover:before,
.yf-tooltip-bottom:hover:after,
.yf-tooltip-bottom:focus:before,
.yf-tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform:    translateY(12px);
  transform:         translateY(12px);
}

/* Right */
.yf-tooltip-right:before,
.yf-tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.yf-tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: $COLOR-YFGREEN;
  //border-right-color: hsla(0, 0%, 20%, 0.9);
}

.yf-tooltip-right:hover:before,
.yf-tooltip-right:hover:after,
.yf-tooltip-right:focus:before,
.yf-tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform:    translateX(12px);
  transform:         translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.yf-tooltip-left:before,
.yf-tooltip-right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.yf-tooltip-left:after,
.yf-tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}




