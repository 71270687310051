/* CSS Document */


/* ==================================================== */
/*             			SIZES 							*/
/* ==================================================== */
// TEXT / PARAGRAPH
$FONT-SIZE-STD:   16px;
$FONT-SIZE-SMALL: 14px;
$FONT-SIZE-SMALL-EXTRA: 12px;
$FONT-SIZE-LARGE: 18px;

// TEXT / PARAGRAPH
$FONT-SIZE-STD-LG-2024: 18px;
$FONT-SIZE-STD-XS-2024: 16px;
$FONT-SIZE-SMALL-2024: 14px;
$FONT-SIZE-LARGE-2024: 20px;

// HEADERS
$FONT-SIZE-H1: 48px;
$FONT-SIZE-H2: 36px;
$FONT-SIZE-H3: 24px;
$FONT-SIZE-H4: 18px;
$FONT-SIZE-H5: 16px;
$FONT-SIZE-H6: 14px;

// HEADERS SMALL
$FONT-SIZE-H1-SMALL: 36px;
$FONT-SIZE-H2-SMALL: 30px;
$FONT-SIZE-H3-SMALL: 22px;
$FONT-SIZE-H4-SMALL: 16px;
$FONT-SIZE-H5-SMALL: 14px;
$FONT-SIZE-H6-SMALL: 14px;

// SPECIAL
$FONT-SIZE-SUPER:       60px;
$FONT-SIZE-SUPER-SMALL: 36px;



/* ==================================================== */
/*         			  DIMENSIONS						*/
/* ==================================================== */

// BOOTSTRAP BREAKPOINTS
$SCREEN-XS: $screen-xs;
$SCREEN-SM: $screen-sm;
$SCREEN-MD: $screen-md;
$SCREEN-LG: $screen-lg;

// NAV (MAIN)
$NAVMAIN-MIN-HEIGHT:   50px;
$NAVMAIN-MAX-HEIGHT:   60px;

// HEADER
$HEADER-MIN-HEIGHT-LG:    560px;
$HEADER-MIN-HEIGHT-MD:    320px;

// SECTION
$SECTION-PADDING-HEIGHT-STD:   45px;
$SECTION-PADDING-HEIGHT-SMALL: 15px;

// CONTAINER
//$MAX-HEIGHT-LARGE:  600px;
$MAX-HEIGHT-SMALL:  400px;

// GALLERY
$MAX-HEIGHT-THUMBNAILS-LARGE: 180px;
$MAX-HEIGHT-THUMBNAILS-SMALL:  80px;


/* ==================================================== */
/*             			COLORS 							*/
/* ==================================================== */

// BLACK / WHITE
$COLOR-WHITE:  #FFFFFF;
$COLOR-BLACK:  #000000;

$COLOR-K0:     #FFFFFF;
$COLOR-K5:     #F6F6F6;
$COLOR-K10:    #ECEDED;
$COLOR-K20:    #D9DADB;
$COLOR-K40:    #B1B3B4;
//$COLOR-K50:    #9D9D9C;
$COLOR-K65:    #7B7C7E;
$COLOR-K80:    #58585A;
$COLOR-K90:    #3E3D40;
$COLOR-K100:   #000000;

// COLORS
$COLOR-YFGREEN:    #97BF15;
$COLOR-YFGREEN-L:  #BDCD00;
$COLOR-YFGREEN-D:  #6AB026;

$COLOR-YFBLUE:     #0099A9;
$COLOR-YFBLUE-L:   #00A7AC;
$COLOR-YFBLUE-D:   #008998;

$COLOR-YFPINK:     #E60073;
$COLOR-YFPINK-L:   #E94282;
$COLOR-YFPINK-D:   #CC0066;

$COLOR-YFORANGE:   #EA5B10;
$COLOR-YFORANGE-L: #EF7D04;
$COLOR-YFORANGE-D: #CF520C;

$COLOR-YFVIOLET:   #702583;
$COLOR-YFVIOLET-L: #964091;
$COLOR-YFVIOLET-D: #642076;


// STANDARD TEXT COLORS
$COLOR-TITLE-STD: $COLOR-K90;
$COLOR-TEXT-STD: $COLOR-K40;














