/* CSS Document */


/**
 * usecases (overview)
 */
//HEADER P {
    //margin-bottom: 5px;
//}



/**
 * references
 */
SECTION.yf-content-reference {
    P { text-align: justify }

    // bullet list custom optimizations
    UL.yf-bullets-left LI {
        SPAN {
            margin-top: 2px;
            I { color: $COLOR-YFORANGE; }
        }

        H1, H2, H3, H4, H5, H6 {
            font-size: $FONT-SIZE-H5;
            color: $COLOR-K80;
        }
    }

    //headlines for links
    SPAN.yf-link-title {
        display: inline-block;
        margin: 20px 0 6px 0;
        color: $COLOR-TITLE-STD;
        font-size: $FONT-SIZE-H4;
    }
}
UL.yf-page-refs-logolist {
    //padding: 20px 0px 20px 0px;

    LI {
        padding: 10px 10px;
        text-align: center;

        IMG {
            padding: 0; margin: 0;
        }
    }
}

/**
 * blog
 */
SECTION.yf-blog-overview {
    H2, H2 A, H3, H3 A {
        font-size: $FONT-SIZE-H2;
    }

    DIV.yf-blog-sidenav-filter {
        SPAN { font-size: $FONT-SIZE-H3; color: $COLOR-TITLE-STD; }

        .nav {
            padding-bottom: 20px;
            LI {
                A {
                    color: $COLOR-TEXT-STD;
                    padding: 3px 10px;
                }
                A:hover {
                    color: $COLOR-YFBLUE;
                    background-color: $COLOR-WHITE;
                }
            }
        }

        .nav-pills {
            LI.active {
                A, A:focus, A:hover {
                    color: $COLOR-YFBLUE;
                    background-color: $COLOR-WHITE;
                }
            }
        }
    }

    .yf-content.yf-blog {
        SPAN {
            color: $COLOR-YFBLUE;
        }
        A:hover {
            H1, H2, H3, H4, H5, H6 {
                color: $COLOR-YFBLUE;
            }
        }
    }
}


SECTION.yf-blog-details {
    H2, H3, H4, H5, H6 {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    P {
        text-align: justify
    }
    P:first-child {
        color: $COLOR-K80;
        font-weight: 700;
    }

    P B, P EM, P STRONG {
        color: $COLOR-YFBLUE;
    }

    IMG {
        padding-bottom: 15px;
    }

    //headlines for links (links titles)
    DIV.yf-blog-list-entry > SPAN {
        display: inline-block;
        margin: 20px 0 6px 0;
        color: $COLOR-TITLE-STD;
        font-size: $FONT-SIZE-H4;
    }

    UL > LI > DIV {
        H2, H3, H4, H5, H6 { padding-bottom: 0px; }
    }
}


/**
 * aboutus
 */
SECTION.yf-section-aboutus-overview {
    background-color: $COLOR-WHITE;

    SPAN {
        color: $COLOR-YFGREEN-L;
    }
}


/**
 * awards
 */
DIV.yf-card.yf-award {
    DIV:nth-child(2) {
        SPAN {
            color: $COLOR-YFGREEN;
        }
    }
    DIV:nth-child(3) {
        margin-top: 10px;
    }
}


/**
 * downloads
 */
DIV.yf-card.yf-download {
    DIV:nth-child(2) {
        SPAN {
            color: $COLOR-YFGREEN;
        }
    }
    DIV:nth-child(3) {
        margin-top: 10px;
    }
}


/**
 * contact form
 */
SECTION.yf-section-contactform {
    H3, H4 {
        font-size: $FONT-SIZE-H3;
    }
}


/**
 * articles (details)
 */
.yf-content.yf-press {
    SPAN {
        color: $COLOR-YFPINK;
    }
    A:hover {
        H1, H2, H3, H4, H5, H6 {
            color: $COLOR-YFGREEN;
        }
    }
}

.yf-article-text {

    > P:first-child {
        color: $COLOR-K80;
        font-weight: bold;
    }


     P B, P EM, P STRONG {
        color: $COLOR-YFPINK;
    }
    SPAN {
        font-weight: bold;
        color: $COLOR-YFPINK;
    }

    IMG {
        padding-bottom: 30px;
        width: 100%;
    }

    .yf-bullets-left {
        @media (min-width: $SCREEN-MD) {
            padding-left: 20px;
            padding-bottom: 20px;
        }
    }

    .yf-bullets-left.yf-singleline LI P , .yf-bullets-left.yf-singleline LI P EM, .yf-bullets-left.yf-singleline LI P STRONG, .yf-bullets-left.yf-singleline LI P B {
        color: $COLOR-K80;
    }

}
.yf-article-links {
    SPAN {
        display: inline-block;
        margin: 20px 0 6px 0;
        color: #3e3d40;
        font-size: 18px;
    }
}




/**
 * includes
 */

//gallery
.yf-inc-section-gallery {
    background-color: $COLOR-K80;

    H1,H2,H3,H4,H5,H6,P {
        color: $COLOR-WHITE;
    }
}
.yf-inc-section-gallery.yf-green {
    background-color: $COLOR-YFGREEN;
}


//contact
.yf-inc-section-contact {
    @media (min-width: $SCREEN-LG) {
        min-height: 520px;
    }
}



