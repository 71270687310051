/* ==================================================== */
/*                      CARDS       				    */
/* ==================================================== */

// BASIC
.yf-card {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  transition:all 0.5s;  // animation

  //min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  //border: 1px solid rgba(0,0,0,.125);
  border: none; border-color: transparent;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);

  margin-bottom: 30px;

  DIV:first-child {
    overflow: hidden;
    text-align: center;

    IMG {
      width:100%;
      object-fit: cover;
      transition:all 0.5s;
    }
    IMG.yf-img-contain {
      width:100%;
      object-fit: contain;
      transition:all 0.5s;
    }
    IMG.yf-img-max-height-320 {
      max-height: 320px;
    }
    IMG:hover {
      transform:scale(1.05);
    }
  }

  H1, H2, H3, H4, H5, H6 {
    font-size: 24px;
  }

  .yf-content.yf-nominheight {
    P {
      min-height: auto;
    }
  }
  .yf-content.yf-footer {
    margin-top: 20px;
  }
  .yf-content {
    SPAN.yf-title {
      font-size: $FONT-SIZE-H3;
      color: $COLOR-K80;
    }

    SPAN.yf-title-small {
      font-size: $FONT-SIZE-H3-SMALL;
      color: $COLOR-K80;
    }

  }



  // sublinks
  A.yf-sublink,
  A.yf-sublink:focus,
  A.yf-sublink:active,
  A.yf-sublink:visited {
    display: block;
    margin: 0px;
    padding: 9px 15px;
    background: $COLOR-WHITE;
    border-top: 1px solid $COLOR-K10;

    font-size: $FONT-SIZE-SMALL;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;

    I {
      margin-right: 6px;
    }
  }

  A.yf-sublink:hover {
    background: $COLOR-K5;
  }
}

.yf-card:hover {
  transform:scale(1.025);
}







// VERTICAL
.yf-card-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;

  /*.yf-title {
      padding: 10px 15px; margin-bottom: 10px;
      //background-color: $COLOR-YFBLUE;
  }*/

  DIV:first-child {
    max-height: 540px;
    IMG {
      max-height: 540px;
    }
    //margin: 0 auto;   --> messes with cards in blog!
  }

  .yf-content {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 10px 15px;
  }

  @media (min-width: $SCREEN-SM) {
    .yf-content {
      P {
        min-height: 200px;
      }
    }
  }
  @media (min-width: $SCREEN-MD) {
    .yf-content {
      P {
        min-height: 160px;
      }
    }
  }
  @media (min-width: $SCREEN-LG) {
    .yf-content {
      P {
        min-height: 120px;
      }
    }
  }

}


// HORIZONTAL
.yf-card-horizontal {
  -webkit-box-orient: row;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;

  .yf-img {
    position: relative;
    width: 50%;

    IMG {
      height: 100%;
    }
  }

  .yf-content {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;

    width: 50%;
    padding: 20px 30px;

  }

  //golden cut
  .yf-img-gc {
    width: 35%;
  }
  .yf-content-gc {
    width: 65%;
  }
}

@media (max-width: $SCREEN-MD) {
  .yf-card-horizontal {
    -webkit-box-orient: column;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;

    .yf-img, .yf-content {
      width: 100%;
    }

    //golden cut
    .yf-img-gc, .yf-content-gc {
      width: 100%;
    }

  }
}



// LOGO
.yf-card.yf-logo {

  DIV:first-child {
    text-align: center;
    margin: 0 auto;

    IMG {
      max-width: 256px;
      transition:none;
    }
    IMG:hover {
      transform:none;
    }
  }

  DIV:nth-child(2) {
    border-top: 3px solid $COLOR-YFGREEN-L;
    //background: $COLOR-K5;
  }
}


// POPUP
.yf-card-popup {
  padding: 0; margin:0;

  .yf-popup-close {
    position: absolute;
    right: 8px; top: 8px;

    I {
      color: $COLOR-WHITE;
    }
  }
}



// REFERENCES
.yf-card-reference {
  DIV:first-child {
    text-align: left;
  }

  DIV.yf-content:first-child {
    text-align: left;
    border-bottom: 3px solid $COLOR-YFORANGE-L;

    IMG {
      max-width: 128px; max-height: 128px;
      transition: none;
      padding-right: 10px; padding-bottom: 10px;
    }
    IMG:hover { transform: none; }
  }

  DIV.yf-content {
    text-align: right; float: right;
    IMG {
      width: auto;
      max-height: 64px;
    }
  }
}




// CARD CONTENT
.yf-card {
  .yf-content.yf-title {
  }

  .yf-content {
    //TITLE
    .yf-title {
      margin-bottom: 20px;
    }

    //PRICE
    .yf-price {
      margin-bottom: 20px;

      .yf-price-discount {
        font-size: 60px;
        font-weight: bold;
      }
      .yf-price-regular {
        font-size: 30px;
        text-decoration: line-through;
      }

    }

    // TABLE
    .yf-table {
      margin-bottom: 10px;

      TABLE {
        width:100%;

        TR TD {
          text-align: center;
        }

        // title
        TR:nth-child(odd) TD {
          padding: 3px;
          border-bottom: 1px solid $COLOR-K10;

          font-size: 12px; font-weight: bold;
          text-transform: uppercase;
          color: $COLOR-TEXT-STD;
        }
        // content
        TR:nth-child(even) TD {
          padding: 3px 3px 20px 3px;

          font-size: 18px; font-weight: normal;
          color: $COLOR-K80;

        }
      }
    }
  }
}




//CARD CONTENT: PRICING
.yf-card-pricing {
  .yf-content {
    P {
      min-height: auto;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    P:nth-child(1) {
      color: $COLOR-K80;
      font-weight: bold;
      text-transform: uppercase;
      padding-bottom: 5px;
    }
    P:nth-child(2), P.yf-desc {
      color: $COLOR-YFBLUE;
      min-height: 120px;
      padding-bottom: 20px;
    }
    DIV.yf-price-month, DIV.yf-price-main {
      text-align: center;
      SPAN:nth-child(1) {
        color: $COLOR-YFBLUE;
        font-size: 36px;
        font-weight:bold;
      }
      padding-bottom: 5px; margin-bottom: 0px;
    }
    HR.yf-divider {
      width: 20px; border-color: $COLOR-K20;
      margin-top: 0; margin-bottom: 10px;
    }
    DIV.yf-price-year, DIV.yf-price-sub {
      text-align: center;
      SPAN:nth-child(1) {
        color: $COLOR-YFBLUE;
        font-size: 16px;

      }
      padding-bottom: 20px; margin-bottom: 20px;
      border-bottom: 1px solid $COLOR-K10;
    }
  }
  UL.yf-bullets-left LI {
    SPAN {
      margin-right: 5px; margin-top: 3px;
      I {
        font-size: 14px;
      }
    }
    DIV {
      font-size: 14px;
      line-height: 1.5;
    }
  }

}

// BEST OFFER (HIGHLIGHTED)
.yf-card-pricing-best {
  color: $COLOR-WHITE;

  @media (min-width: $SCREEN-MD) {
    transform: scale(1.025);
  }

  .yf-content {
    DIV, P, SPAN, DIV P, P:nth-child(1), P:nth-child(2), DIV.yf-price-month SPAN:nth-child(1), DIV.yf-price-year SPAN:nth-child(1), UL.yf-icon-yfblue I {
      color: $COLOR-WHITE;
    }
  }
}
.yf-card-pricing-best:hover {
  color: $COLOR-WHITE;
  transform: scale(1.05);
}




.yf-card-vs {
  UL LI DIV H4 { font-size: $FONT-SIZE-LARGE; padding-top: 4px; padding-bottom: 0px; margin-bottom: 0px; }


}