#cookiesBtn {
	//background:url('/cookie-icon.png')no-repeat center;
	//background-size:cover;
	width:50px;
	height:50px;
	border:0;
	cursor:pointer;
	position:fixed;
	left:15px;
	bottom:15px;
}
#cookiesBtn:focus{
	outline:none;
}


#bs-gdpr-cookies-modal-body li {
	list-style:none;
}
#bs-gdpr-cookies-modal-body li .cookie_table {
	display:none;
	margin-bottom:15px;
}


// OVERLAY: FOOTER
#cookie-footer {
	display:none; // important for javascript features (show/hide)!
}


/*
a.cookie_ts{
	color:#0062cc!important;
	font-size:14px;
	cursor:pointer;
}
.cookie_row,
.cookie_tbody{
	clear: both;
    overflow: hidden;
}
.cookie_table .cookie_row>div,
.cookie_table .cookie_t{
	width:20%;
	float:left;
	padding:5px;
	overflow:hidden;
	border-bottom:1px solid #eee;
}
.cookie_table .cookie_thead{
	background:#eee;
	overflow:hidden;
	clear:both;
}
*/