/* ==================================================== */
/*                    POSITIONING		    	    	*/
/* ==================================================== */
// STANDARDS
.yf-pos-relative {
  position: relative;
}
.yf-pos-absolute {
  position:absolute;
}
.yf-float-left {
  float: left;
}
.yf-float-right {
  float: right;
}

.yf-inline {
  display: inline;
}

.yf-z-auto { z-index: auto; }
.yf-z-1111 { z-index: 1111; }
.yf-z-9999 { z-index: 9999; }



// CENTER (IMG)
@media (min-width: $SCREEN-MD) {
  .yf-img-center {
    margin: 0 auto;
  }
  .yf-img-right {
    margin-left: auto;
  }
}


// HORIZONTAL CENTER
.yf-center {
  margin: 0 auto;
}

// VERTICAL CENTER
.yf-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //    align-items: center;
}
.yf-vertical-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  //align-items: baseline;
}

// EQUAL ROWS
.yf-row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}


// EQUAL ROWS VIA CLEARFIX
.yf-list-equal-height:nth-child(even) {
  LI::after { content: "<div class=\"clearfix\"></div>"; }
}




/* ==================================================== */
/*                 LAYOUTS (CONTAINER)       	    	*/
/* ==================================================== */

// CONTAINER (STACKED: ABSOLUTE)
.yf-container-stacked {
  position: absolute;
  overflow: hidden;
  top:0; left:0;
  width:100%; height:100%;
  //z-index:-9999;
}

// break at SM
.yf-container-stacked-break-sm {
  overflow: hidden;
}

@media (max-width: $SCREEN-SM) {
  .yf-container-stacked-break-sm {
    max-height: $MAX-HEIGHT-SMALL;
  }
  .yf-container-stacked-break-sm IMG {
    object-fit: cover;
  }
}
@media (min-width: $SCREEN-SM) {
  .yf-container-stacked-break-sm {
    position: absolute;
    width: 100%; height: 100%;
    padding: 0;
  }
  .yf-container-stacked-break-sm IMG {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .yf-container-stacked-break-sm.yf-right-half {
    left: 50%;
    width: 50%;
  }
  .yf-container-stacked-break-sm.yf-left-half {
    left: 0%;
    width: 50%;
  }
}


// break at MD
.yf-container-stacked-break-md {
  overflow: hidden;
}

@media (max-width: $SCREEN-MD) {
  .yf-container-stacked-break-md {
    max-height: $MAX-HEIGHT-SMALL;
  }
  .yf-container-stacked-break-md IMG {
    object-fit: cover;
  }
}
@media (min-width: $SCREEN-MD) {
  .yf-container-stacked-break-md {
    position: absolute;
    width: 100%; height: 100%;
    padding: 0;
  }
  .yf-container-stacked-break-md IMG {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .yf-container-stacked-break-md.yf-right-half {
    left: 50%;
    width: 50%;
  }
  .yf-container-stacked-break-md.yf-left-half {
    left: 0%;
    width: 50%;
  }
}



/* ==================================================== */
/*              LAYOUTS	(mainly: Sections)      		*/
/* ==================================================== */

// GENERAL
.yf-layout-stacked {
  position: relative;
  padding-top: 0px; padding-bottom: 0px; margin-top: 0px; margin-bottom: 0px;

  .yf-animate {
    transition:opacity 1s; // animation
    opacity: 1.0;
  }
  .yf-animate:hover {
    opacity: 0.75;
  }


  // left & right
  .yf-content-left {
    @media (min-width: $SCREEN-SM) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .yf-content-right {
    @media (min-width: $SCREEN-SM) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}


// KEN BURNS (FULL)
.yf-layout-kenburns {
  .carousel-inner {
    height: 100%;
    .item {
      height: 100%;
      IMG {
        height: 100%;
      }
    }
  }
}

// LEFT / RIGHT (LR)
.yf-layout-lr {
  DIV > EM,
  DIV > STRONG,
  DIV > B {
    color: $COLOR-YFGREEN;
  }
}
@media (min-width: $SCREEN-MD) {
  .yf-layout-lr {
    IMG {
      margin: 0 auto; // center images
    }
  }
}

// TEXT                 >>> DELETE???
.yf-layout-text {
  P {
    text-align: justify;
  }

  IMG {
    padding-bottom: 15px;
  }

  H2, H3, H4, H5, H6 {
    padding-top: 30px;
  }

}




/* ==================================================== */
/*                       SPACES							*/
/* ==================================================== */
.yf-space-top-std     { padding-top:    30px; }
.yf-space-bottom-std  { padding-bottom: 30px; }
.yf-space-height-std  { padding-top:    30px; padding-bottom:  30px; }

.yf-space-height-50   { padding-top:    50px; padding-bottom:  50px; }
.yf-space-height-100  { padding-top:   100px; padding-bottom: 100px; }
.yf-space-height-textblock  { padding-top:   50px; padding-bottom: 150px; }

@media (max-width: $SCREEN-XS) {
  .yf-space-top-std     { padding-top:    15px; }
  .yf-space-bottom-std  { padding-bottom: 15px; }
  .yf-space-height-std  { padding-top:    15px; padding-bottom: 15px; }
}
@media (max-width: $SCREEN-SM) {
  .yf-space-height-50   { padding-top:    25px; padding-bottom: 25px; }
  .yf-space-height-100  { padding-top:    50px; padding-bottom: 50px; }
}
@media (max-width: $SCREEN-MD) {
  .yf-space-height-textblock  { padding-top: 50px; padding-bottom: 50px; }
}

.yf-nospace-height { padding-top: 0px; padding-bottom: 0px; margin-top: 0px; margin-bottom: 0px; }

.yf-space-top-3         { padding-top:   3px; }
.yf-space-top-6         { padding-top:   6px; }
.yf-space-top-9         { padding-top:   9px; }
.yf-space-top-12        { padding-top:  12px; }
.yf-space-top-15        { padding-top:  15px; }
.yf-space-top-20        { padding-top:  20px; }
.yf-space-top-30        { padding-top:  30px; }
.yf-space-top-50        { padding-top:  50px; }
.yf-space-top-100       { padding-top: 100px; }
.yf-space-top-200       { padding-top: 200px; }

.yf-space-bottom-3      { padding-bottom:   3px; }
.yf-space-bottom-6      { padding-bottom:   6px; }
.yf-space-bottom-9      { padding-bottom:   9px; }
.yf-space-bottom-12     { padding-bottom:  12px; }
.yf-space-bottom-15     { padding-bottom:  15px; }
.yf-space-bottom-20     { padding-bottom:  20px; }
.yf-space-bottom-30     { padding-bottom:  30px; }
.yf-space-bottom-50     { padding-bottom:  50px; }
.yf-space-bottom-100    { padding-bottom: 100px; }
.yf-space-bottom-200    { padding-bottom: 200px; }

.yf-space-left-15       { padding-left:  15px; }
.yf-space-left-30       { padding-left:  30px; }
.yf-space-left-50       { padding-left:  50px; }
.yf-space-left-100      { padding-left: 100px; }





.yf-title-divider {
  width: 100px;
  border-width: 5px;
  border-color: $COLOR-YFGREEN;
}




/* ==================================================== */
/*                       LOGOS							*/
/* ==================================================== */
.yf-img-logo-matrix {
  padding-bottom: 25px;

  @media (min-width: $SCREEN-XS) {
    padding-bottom: 25px;
  }
  @media (min-width: $SCREEN-SM) {
    padding-bottom: 50px;
  }
  @media (min-width: $SCREEN-MD) {
    padding-bottom: 75px;
  }
}






