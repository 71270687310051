/* ==================================================== */
/*                       BUTTONS						*/
/* ==================================================== */
.yf-btn,
.yf-btn:focus,
.yf-btn:active,
.yf-btn:visited {
  display: inline-block;
  margin-bottom: 10px; margin-right: 10px;
  padding: 9px 18px;

  /*-webkit-transition: background 1s ease-out;
  -moz-transition: background 1s ease-out;
  -o-transition: background 1s ease-out;
  transition: background 1s ease-out;*/

  //border: 2px solid transparent;
  border-radius: 3px;
  color: $COLOR-WHITE;
  font-size: $FONT-SIZE-STD;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  I {
    margin-right: 6px;
  }
}
.yf-btn:hover {
  //border: 1px solid transparent;
  color: $COLOR-WHITE;
}

// SMALL
.yf-btn.yf-small,
.yf-btn.yf-small:focus,
.yf-btn.yf-small:active,
.yf-btn.yf-small:visited  {
  margin-bottom: 6px; margin-right: 6px;
  padding: 7px 16px;
  font-size: 14px;
  text-align: left;
  //border-width: 1px !important;
}

// BIG
.yf-btn.yf-big,
.yf-btn.yf-big:focus,
.yf-btn.yf-big:active,
.yf-btn.yf-big:visited  {
  text-align: left;
  padding: 16px 18px;
  margin-right: 0px;

  DIV {
    display: inline-block;
    vertical-align: bottom;
    I { margin-bottom: 5px; margin-right: 10px; }
    SPAN {
      color: $COLOR-WHITE;
      display: block;
      line-height: normal;
    }
    SPAN:nth-child(1) { font-weight: bold; }
    SPAN:nth-child(2) { font-size: 12px; }
  }
}

// CENTERED
.yf-btn.yf-centered,
.yf-btn.yf-centered:focus,
.yf-btn.yf-centered:active,
.yf-btn.yf-centered:visited  {
  margin-bottom: 6px; margin-right: 6px;
  padding: 6px 9px;
  font-size: 14px;
  text-align: center;
  I:first-child {
    display: block;
    margin: 0 auto;
    padding-bottom:6px;
  }
}



// YF-GREEN
.yf-btn.yf-yfgreen {
  @include mixin_yf-bg-linear-dg-yfgreen();
  color: $COLOR-WHITE
}
.yf-btn.yf-yfgreen:hover {
  background: $COLOR-YFGREEN-L;
}
// border (-bd)
.yf-btn.yf-yfgreen-bd {
  border-radius: 4px;
  border: 2px solid $COLOR-YFGREEN;
  padding: 7px 16px;
  color: $COLOR-YFGREEN;
}
.yf-btn.yf-yfgreen-bd:hover {
  background: $COLOR-YFGREEN-L;
  border: 2px solid $COLOR-YFGREEN-L;
  color: $COLOR-WHITE;
  //    background: $COLOR-WHITE;
  //    border: 2px solid $COLOR-YFGREEN-L;
  //    color: $COLOR-YFGREEN-L;
}


// YF-BLUE
.yf-btn.yf-yfblue {
  @include mixin_yf-bg-linear-dg-yfblue();
  color: $COLOR-WHITE
}
.yf-btn.yf-yfblue:hover {
  background: $COLOR-YFBLUE-L;
}
// border (-bd)
.yf-btn.yf-yfblue-bd {
  border-radius: 4px;
  border: 2px solid $COLOR-YFBLUE;
  padding: 7px 16px;
  color: $COLOR-YFBLUE;
}
.yf-btn.yf-yfblue-bd:hover {
  background: $COLOR-YFBLUE-L;
  border: 2px solid $COLOR-YFBLUE-L;
  color: $COLOR-WHITE;
  //    background: $COLOR-WHITE;
  //    border: 2px solid $COLOR-YFBLUE-L;
  //    color: $COLOR-YFBLUE-L;
}


// YF-PINK
.yf-btn.yf-yfpink {
  @include mixin_yf-bg-linear-dg-yfpink();
  color: $COLOR-WHITE
}
.yf-btn.yf-yfpink:hover {
  background: $COLOR-YFPINK-L;
}
// border (-bd)
.yf-btn.yf-yfpink-bd {
  border-radius: 4px;
  border: 2px solid $COLOR-YFPINK;
  padding: 7px 16px;
  color: $COLOR-YFPINK;
}
.yf-btn.yf-yfpink-bd:hover {
  background: $COLOR-YFPINK-L;
  border: 2px solid $COLOR-YFPINK-L;
  color: $COLOR-WHITE;
  //    background: $COLOR-WHITE;
  //    border: 2px solid $COLOR-YFPINK-L;
  //    color: $COLOR-YFPINK-L;
}


// YF-ORANGE
.yf-btn.yf-yforange {
  @include mixin_yf-bg-linear-dg-yforange();
  color: $COLOR-WHITE
}
.yf-btn.yf-yforange:hover {
  background: $COLOR-YFORANGE-L;
}
// border (-bd)
.yf-btn.yf-yforange-bd {
  border-radius: 4px;
  border: 2px solid $COLOR-YFORANGE;
  padding: 7px 16px;
  color: $COLOR-YFORANGE;
}
.yf-btn.yf-yforange-bd:hover {
  background: $COLOR-YFORANGE-L;
  border: 2px solid $COLOR-YFORANGE-L;
  color: $COLOR-WHITE;
  //    background: $COLOR-WHITE;
  //    border: 2px solid $COLOR-YFORANGE-L;
  //    color: $COLOR-YFORANGE-L;
}


// YF-VIOLET
.yf-btn.yf-yfviolet {
  @include mixin_yf-bg-linear-dg-yfviolet();
  color: $COLOR-WHITE
}
.yf-btn.yf-yfviolet:hover {
  background: $COLOR-YFVIOLET-L;
}
// border (-bd)
.yf-btn.yf-yfviolet-bd {
  border-radius: 4px;
  border: 2px solid $COLOR-YFVIOLET;
  padding: 7px 16px;
  color: $COLOR-YFVIOLET;
}
.yf-btn.yf-yfviolet-bd:hover {
  background: $COLOR-YFVIOLET-L;
  border: 2px solid $COLOR-YFVIOLET-L;
  color: $COLOR-WHITE;
  //    background: $COLOR-WHITE;
  //    border: 2px solid $COLOR-YFVIOLET-L;
  //    color: $COLOR-YFVIOLET-L;
}


// GREY
.yf-btn.yf-grey {
  background: $COLOR-K20;
  color: $COLOR-WHITE
}
.yf-btn.yf-grey:hover {
  background: $COLOR-K40;
}
// border (-bd)
.yf-btn.yf-grey-bd {
  border: 2px solid $COLOR-K20;
  padding: 7px 18px;
  color: $COLOR-K40;
}
.yf-btn.yf-grey-bd:hover {
  background: $COLOR-K40;
  border: 2px solid $COLOR-K40;
  color: $COLOR-WHITE;
  //    background: $COLOR-WHITE;
  //    border: 2px solid $COLOR-K40;
  //    color: $COLOR-K65;
}
.yf-small.yf-grey-bd {
  padding: 4px 18px;
}


// WHITE
.yf-btn.yf-white {
  background: $COLOR-WHITE;
  color: $COLOR-K65;
}
.yf-btn.yf-white:hover {
  border: 2px solid $COLOR-K40;
  //color: $COLOR-K40;
  padding: 7px 16px;
}
// border (-bd)
.yf-btn.yf-white-bd {
  border: 2px solid $COLOR-WHITE;
  background: rgba(255,255,255, 0.1);
  padding: 7px 18px;
  color: $COLOR-K65;
}
.yf-btn.yf-white-bd:hover {
  background: $COLOR-WHITE;
  border: 2px solid $COLOR-K40;
}


// WHITE-YF-GREEN
.yf-btn.yf-white-yfgreen {
  background: $COLOR-WHITE;
  color: $COLOR-YFGREEN;
}
.yf-btn.yf-white-yfgreen:hover {
  border: 2px solid $COLOR-YFGREEN-L;
  color: $COLOR-YFGREEN-L;
  padding: 7px 16px;
}
// border (-bd)
.yf-btn.yf-white-bd-yfgreen {
  border: 2px solid $COLOR-WHITE;
  background: rgba(255,255,255, 0.1);
  padding: 7px 18px;
  color: $COLOR-YFGREEN;
}
.yf-btn.yf-white-bd-yfgreen:hover {
  background: $COLOR-YFGREEN-L;
  border: 2px solid $COLOR-WHITE;
  color: $COLOR-WHITE;
}

// WHITE-YF-BLUE
.yf-btn.yf-white-yfblue {
  background: $COLOR-WHITE;
  color: $COLOR-YFBLUE;
}
.yf-btn.yf-white-yfblue:hover {
  border: 2px solid $COLOR-YFBLUE-L;
  color: $COLOR-YFBLUE-L;
  padding: 7px 16px;
}
// border (-bd)
.yf-btn.yf-white-bd-yfblue {
  border: 2px solid $COLOR-WHITE;
  background: rgba(255,255,255, 0.1);
  padding: 7px 18px;
  color: $COLOR-YFBLUE;
}
.yf-btn.yf-white-bd-yfblue:hover {
  background: $COLOR-WHITE;
  border: 2px solid $COLOR-YFBLUE-L;
  color: $COLOR-YFBLUE-L;
}

// WHITE-YF-PINK
.yf-btn.yf-white-yfpink {
  background: $COLOR-WHITE;
  color: $COLOR-YFPINK;
}
.yf-btn.yf-white-yfpink:hover {
  border: 2px solid $COLOR-YFPINK-L;
  color: $COLOR-YFPINK-L;
  padding: 7px 16px;
}
// border (-bd)
.yf-btn.yf-white-bd-yfpink {
  border: 2px solid $COLOR-WHITE;
  background: rgba(255,255,255, 0.1);
  padding: 7px 18px;
  color: $COLOR-YFPINK;
}
.yf-btn.yf-white-bd-yfpink:hover {
  background: $COLOR-WHITE;
  border: 2px solid $COLOR-YFPINK-L;
  color: $COLOR-YFPINK-L;
}

// WHITE-YF-ORANGE
.yf-btn.yf-white-yforange {
  background: $COLOR-WHITE;
  color: $COLOR-YFORANGE;
}
.yf-btn.yf-white-yforange:hover {
  border: 2px solid $COLOR-YFORANGE-L;
  color: $COLOR-YFORANGE-L;
  padding: 7px 16px;
}
// border (-bd)
.yf-btn.yf-white-bd-yforange {
  border: 2px solid $COLOR-WHITE;
  background: rgba(255,255,255, 0.1);
  padding: 7px 18px;
  color: $COLOR-YFORANGE;
}
.yf-btn.yf-white-bd-yforange:hover {
  background: $COLOR-WHITE;
  border: 2px solid $COLOR-YFORANGE-L;
  color: $COLOR-YFORANGE-L;
}

// WHITE-YF-VIOLET
.yf-btn.yf-white-yfviolet {
  background: $COLOR-WHITE;
  color: $COLOR-YFVIOLET;
}
.yf-btn.yf-white-yfviolet:hover {
  border: 2px solid $COLOR-YFVIOLET-L;
  color: $COLOR-YFVIOLET-L;
  padding: 7px 16px;
}
// border (-bd)
.yf-btn.yf-white-bd-yfviolet {
  border: 2px solid $COLOR-WHITE;
  background: rgba(255,255,255, 0.1);
  padding: 7px 18px;
  color: $COLOR-YFVIOLET;
}
.yf-btn.yf-white-bd-yfviolet:hover {
  background: $COLOR-WHITE;
  border: 2px solid $COLOR-YFVIOLET-L;
  color: $COLOR-YFVIOLET-L;
}


// TRANSPARENT
.yf-btn.yf-transparent {
  color: $COLOR-WHITE;
  background: rgba(0,0,0, 0.3);
}
.yf-btn.yf-transparent:hover {
  background: rgba(0,0,0, 0.2);
}


// GLASS
.yf-btn.yf-glass {
  border: 2px solid $COLOR-WHITE;
  color: $COLOR-WHITE;
  background: rgba(0,0,0, 0.1);
  padding: 7px 16px;

}
.yf-btn.yf-glass:hover {
  background: rgba(0,0,0, 0.3);
}

