/* ==================================================== */
/*               LISTS, BULLETS     				    */
/* ==================================================== */

// GENERAL
UL {
  LI {
    H1, H2, H3, H4, H5, H6 {
      font-size: 20px;
      padding-top: 1px;
      padding-bottom: 0px;
    }
    P {
      margin-bottom: 0px;
    }
  }
}

UL.yf-singleline LI {
  margin-bottom: 15px;

  H1, H2, H3, H4, H5, H6 {
    font-size: 18px;
    padding-top: 2px;
  }

  P {
    color: $COLOR-K80;
  }
}

UL.yf-bullets-classic {
  margin: 0 0 20px 20px;

  LI {
    margin-bottom: 5px;
    list-style: circle;
  }
}

UL.yf-list-floated {
  LI {
    float: left;
  }
}



// YF-BULLETS-LEFT
.yf-bullets-left {
  LI {
    margin-bottom: 20px;

    H1, H2, H3, H4, H5, H6 {
      padding-top: 1px;
    }

    SPAN {
      float: left;
      display: inline-block;
      margin-right: 20px;
      margin-top: 5px;
    }

    IMG, I {
      //color: $COLOR-YFGREEN;

      display: table-cell;
      text-align: center;
      transition: 0.5s;
      vertical-align: middle;

      min-width: 50px;
      height: 50px;
      font-size: 50px;
    }

    DIV {
      display: table-cell;
    }

    P {
      clear: both;
      font-weight:normal;
      color: $COLOR-TEXT-STD;
    }
  }
}





.yf-bullets-left.yf-small {
  SPAN {
    margin-right: 15px;
  }

  IMG, I {
    min-width: 20px;
    height: 20px;
    font-size: 20px;
  }
}
.yf-bullets-left.yf-big {
  IMG, I {
    min-width: 100px;
    height: 100px;
    font-size: 100px;
  }
}

.yf-bullets-left.yf-centered {
  text-align: center;

  LI {
    display: inline-block;
    margin-right: 30px;
  }
}

.yf-bullets-left.yf-centered-singleline {
  text-align: center;

  LI {
    width: 100%;

    DIV {
      text-align: center;
      display: inline-block;
    }

  }
}




// YF-BULLETS-LEFT-STACKED
.yf-bullets-left.yf-stacked {
  SPAN {
    margin-right: 15px;
  }

  IMG, I {
    color: $COLOR-WHITE;

    min-width: 50px;
    height: 50px;
    font-size: 30px;
  }

  I.fa-shopping-cart, I.fa-tv, I.fa-th {
    font-size: 26px;
  }
}

.yf-bullets-left.yf-stacked.yf-small {
  IMG, I {
    min-width: 25px;
    height: 25px;
    font-size: 15px;
  }
}
.yf-bullets-left.yf-stacked.yf-big {
  IMG, I {
    min-width: 100px;
    height: 100px;
    font-size: 60px;
  }
}


// YF-BULLETS-CENTER
.yf-bullets-center {
  padding-top: 10px;

  LI {
    padding-bottom: 30px;

    P {
      margin-bottom: 5px;
    }
  }
  LI[class*=clearfix] {
    padding-bottom: 0px;
  }
  SPAN {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    margin-bottom: 10px;
  }

  //SPAN > IMG {
  //  margin-bottom: 8px;
  //}

  IMG, I {
    color: $COLOR-YFGREEN;

    /*display: table-cell;
    text-align: center;
    transition: 0.5s;
    vertical-align: middle;*/

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    min-width: 100px;
    height: 100px;
    font-size: 100px;
  }

  H1, H2, H3, H4, H5, H6 {
    margin-bottom: 5px;
  }

  EM {
    color: $COLOR-WHITE;
    text-transform: uppercase;
    font-weight: normal;
    display: block;
  }

}

.yf-bullets-center.yf-small {
  IMG, I {
    min-width: 50px;
    height: 50px;
    font-size: 50px;
  }

}

// YF-BULLETS-CENTER STACKED
.yf-bullets-center.yf-stacked {
  SPAN {
    width: 100px;
    height: 100px;

  }
  I {
    color: $COLOR-WHITE;
    font-size: 50px;
  }
  IMG {
    min-width: 68px;
    height: 68px;
    //margin-top: 16px;
  }

}
.yf-bullets-center.yf-stacked.yf-small {
  IMG, I {
    min-width: 50px;
    height: 50px;
    font-size: 30px;
  }
}




// UL: BULLET TITLES
UL.yf-title-bold {
  H1, H2, H3, H4, H5, H6 {
    font-weight: bold;
  }
}
UL.yf-title-white {
  H1, H2, H3, H4, H5, H6 {
    color: $COLOR-WHITE;
  }
}
UL.yf-title-yfblue {
  H1, H2, H3, H4, H5, H6 {
    color: $COLOR-YFBLUE;
  }
}
UL.yf-title-yfgreen {
  H1, H2, H3, H4, H5, H6 {
    color: $COLOR-YFGREEN;
  }
}



// GENERAL
IMG.yf-bullet-img {
  padding: 0; margin: 0;
}