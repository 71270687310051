/* ==================================================== */
/*                      POPUPS       				    */
/* ==================================================== */

// STANDARD
.yf-popup {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 9999;
  //margin: 0 15px;
}

// COOKIES
.yf-popup.yf-cookies {
  background-color: rgba(0,0,0,.75);

  P {
    color: $COLOR-WHITE;
  }

  .yf-inner {
    padding: 30px;

    @media (max-width: $SCREEN-XS) {
      padding: 15px;
    }

    .yf-text {
      SPAN {
        font-size: $FONT-SIZE-H3;
        margin-bottom: 20px;
        color: $COLOR-WHITE;
      }

      P {
        text-align: justify;
      }
    }


    .yf-buttons {
      BUTTON {
        width: 100%;
      }
      BUTTON#cookie_details {
        //border: 2px solid $COLOR-WHITE;
      }

    }

  }

  @media (max-width: $SCREEN-SM) {
    //width: 100%;
    left: 0px; bottom: 0px;
    margin: 0px;
  }

  @media (min-width: $SCREEN-MD) {
    left: 30px; bottom: 15px;
    width: 400px;
  }



}